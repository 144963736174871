import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Formik, Field, Form } from 'formik';
import axios from 'axios';
import { baseUrl } from "../../helpers/baseUrl";
import PasswordSchema from "../../validation/ChangePasswordSchema";
import SwalMsg from '../../helpers/SwalMsg';

function ChangePage() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const initialValues = {
        password: "",
        repeatpassword: ""
    }

    useEffect(() => { console.log(token) }, []);

    if (!token || token === "") {
        Swal.fire({
            title: '¡Solicitud no valida!',
            text: 'No has proporcionado un token',
            icon: 'warning',
            padding: '2em',
            confirmButtonText: 'Aceptar',
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/login')
            }
        }
        );
    }

    return (
        <div class="auth-container d-flex h-100">

            <div class="container mx-auto align-self-center">

                <div class="row">

                    <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-12 d-flex flex-column align-self-center mx-auto">
                        <div class="card mt-3 mb-3">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-md-12 mb-3">

                                        <div class="media mb-4">

                                            <div class="avatar avatar-lg me-3">
                                                <img alt="avatar" src="https://grupocyasv.nyc3.cdn.digitaloceanspaces.com/img-users/default.png" class="rounded-circle" />
                                            </div>

                                            <div class="media-body align-self-center">
                                                <h3 class="mb-0">Cambio de contraseña</h3>
                                                <p class="mb-0">Ingresa tu nueva contraseña</p>
                                            </div>
                                        </div>
                                    </div>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={PasswordSchema}
                                        validateOnChange={true}
                                        validateOnBlur={false}
                                        onSubmit={async (values) => {
                                            console.log(values)
                                            const password = { password: values.password };
                                            try {
                                                const response = await axios.post(`${baseUrl.url}auth/change-password-reset?token=${token}`, password, {
                                                    timeout: 5000,
                                                });

                                                if (response.status === 200) {
                                                    Swal.fire({
                                                        title: '¡Contraseña modificada!',
                                                        text: 'Tu contraseña ha sido actualizada',
                                                        icon: 'success',
                                                        padding: '2em',
                                                        confirmButtonText: 'Aceptar',
                                                    });

                                                    setTimeout(function () {
                                                        navigate("/login");
                                                    }, 3000);
                                                }
                                            } catch (error) {
                                                console.log(error);
                                                Swal.fire({
                                                    title: '¡Contraseña NO modificada!',
                                                    text: 'El token NO es valido o ya caduco',
                                                    icon: 'error',
                                                    padding: '2em',
                                                    confirmButtonText: 'Aceptar',
                                                });

                                                setTimeout(function () {
                                                    navigate("/login");
                                                }, 3000);
                                            }
                                        }}>

                                        {({ handleChange, handleSubmit, errors }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <div class="col-12">
                                                    <div class="mb-4">
                                                        <label class="form-label">Nueva contraseña</label>
                                                        <Field
                                                            type="password"
                                                            className="form-control"
                                                            id="password"
                                                            name="password"
                                                            onChange={handleChange}
                                                        />
                                                        {errors.password ? (
                                                            <div className="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                                {errors.password}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="mb-4">
                                                        <label class="form-label">Repite tu contraseña</label>
                                                        <Field
                                                            type="password"
                                                            className="form-control"
                                                            id="repeatpassword"
                                                            name="repeatpassword"
                                                            onChange={handleChange}
                                                        />
                                                        {errors.repeatpassword ? (
                                                            <div className="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                                {errors.repeatpassword}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-12 text-center my-4">
                                                    Ocurrio un error
                                                </div>
                                                <div class="col-12">
                                                    <div class="mb-4">
                                                        <button class="btn btn-secondary w-100">CAMBIAR</button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>



                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default ChangePage;