import * as Yup from 'yup';

const AdvancesSchema = Yup.object().shape({
  date_received: Yup.date()
    .required('La fecha de recepción es obligatoria'),

  quantity: Yup.number()
    .required('La cantidad es obligatoria')
    .min(1, 'La cantidad debe ser mayor que 0'),

  received_by: Yup.string()
    .required('El campo de "recibido por" es obligatorio'),

  details: Yup.string()
    .required('Los detalles son obligatorios'),
});

export default AdvancesSchema;
