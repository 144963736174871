import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { baseUrl } from "../../../helpers/baseUrl";
import debounce from 'lodash/debounce';
import { useData } from '../../../context/dataContext';
import configureAxios from "../../../helpers/axiosConfig";
import "../css/user-profile.css";

function SaleFormPage01() {
    const axios = configureAxios();
    const user = JSON.parse(sessionStorage.getItem("userData")) ?? null;
    const id_user = user.id_user;
    const initialStateCustomer = {
        first_name: '',
        email: '',
        phone_number: '',
    };
    const [customer, setCustomer] = useState(initialStateCustomer);
    const { setCustomerData } = useData();
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '10px',
            height: '40px',
        })
    };

    const searchData = debounce(async (value) => {
        if (value !== '') {
            try {
                const response = await axios.get(`${baseUrl.url}customers/search`, {
                    params: {
                        name: value,
                        id: id_user
                    }
                });

                const data = response.data.data;
                if (Array.isArray(data)) {
                    const options = data.map(cliente => ({
                        value: cliente.id_customer,
                        label: `${cliente.first_name} ${cliente.last_name}`,
                        details: cliente,
                    }));

                    setOptions(options);
                } else {
                    console.error("La respuesta de la API no es un array válido.");
                }
            } catch (error) {
                console.error(error);
            }
        }
    }, 500);

    useEffect(() => {
        searchData(inputValue);
    }, [inputValue]);

    const handleInputChange = newValue => {
        setInputValue(newValue);
    };

    const handleChange = selectedOption => {
        setOptions([]);
        setCustomer(selectedOption ? selectedOption.details : null);
        setCustomerData(selectedOption ? selectedOption.details : null);
    };

    return (
        <>
            <div className="invoice-address-client-fields">

                <><div className="form-group row" id='selectOption'>
                    <div className="col-sm-12">
                        <Select
                            options={options}
                            value={null}
                            onInputChange={handleInputChange}
                            onChange={handleChange}
                            placeholder="Escribe para buscar un cliente"
                            inputValue={inputValue}
                            styles={customStyles}
                            id='select' />
                    </div>
                </div>
                </>

                <p className='mt-4'>Detalles del cliente seleccionado</p>

                {customer.first_name !== "" && (
                    <div className="user-profile">
                        <div className="widget-content widget-content-area">
                            <div className="text-center user-info">
                                <p className="">{customer.first_name} {customer.last_name}</p>
                            </div>
                            <div className="user-info-list">
                                <div className="">
                                    <ul className="contacts-block list-unstyled w-100">
                                        <li className="contacts-block__item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-mail me-3"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>{customer.email}
                                        </li>
                                        <li className="contacts-block__item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-phone me-3"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg> {customer.phone_number}
                                        </li>
                                        <li className="contacts-block__item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-map-pin me-3"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>El Salvador
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default SaleFormPage01; 