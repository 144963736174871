import React, { useEffect, useRef, useState } from 'react';
import flatpickr from 'flatpickr';
import { baseUrl } from "../../../helpers/baseUrl";
import "../css/flatpickr.css";
import "../css/custom-flatpickr.css";
import { useData } from '../../../context/dataContext';
import configureAxios from "../../../helpers/axiosConfig";

function SaleFormPage03() {
    const axios = configureAxios(); 
    const flatpickrRef = useRef(null);
    const { orderDetails, setOrderDetails, addressData, setAddressData } = useData();
    const currentDate = new Date().toISOString().split('T')[0];
    const [departments, setDepartments] = useState([]);
    const [city, setCity] = useState([]);

    useEffect(() => {
        flatpickr(flatpickrRef.current, {
            noCalendar: false,
            enableTime: false,
            dateFormat: 'Y-m-d',
        }, []);
    });

    useEffect(() => {
        getDepartments();
    }, []);

    const getDepartments = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}departments`);

            setDepartments(response.data.data);

        } catch (error) {
            console.error('Error al obtener los datos de las ventas: ', error);
        }
    }


    const handleInputChange = async (event) => {
        const selectedOption = event.target.selectedOptions[0];
        const value = selectedOption.getAttribute("data-name");

        const id = parseInt(event.target.value, 10);

        try {

            if (id !== 0 || id === undefined || id === null) {
                const response = await axios.get(`${baseUrl.url}departments/${id}`);
                setCity(response.data.data);
                document.getElementById("city").disabled = false; 
            } else {
                setCity([]); 
                document.getElementById("city").disabled = true; 
            }
        } catch (error) {
            console.error('Error al obtener los datos de las ciudades: ', error);
        }

        setAddressData({
            ...addressData,
            city: '',
            department: value,
        });
        
    };

    const handleInputChange2 = (event) => {
        const { name, value } = event.target;

        setAddressData({
            ...addressData,
            [name]: value,
        });
    };

    const handleDateChange = () => {
        const newDate = flatpickrRef.current.value;
        setOrderDetails((prevOrderDetails) => ({
            ...prevOrderDetails,
            order_date: currentDate,
            delivery_date: newDate
        }));
    };

    return (
        <>
            <div>
                
                <p>Ingrese la direccion de entrega</p>
                <div className="row mb-4">
                    <div className="col-xl-4 col-md-4 col-sm-12 mt-2">
                        <div className="form-group">
                            <label >No. de casa</label>
                            <input type="text" className="form-control form-control-sm" name='home_number' onChange={handleInputChange2} value={addressData.home_number} />
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12 mt-2">
                        <div className="form-group">
                            <label >Fecha de entrega</label>
                            <input type="date" ref={flatpickrRef} onInput={handleDateChange} className="form-control form-control-sm flatpickr-input" />
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12 mt-2">
                        <div className="form-group">
                            <label >Fecha de registro</label>
                            <input type="date" value={currentDate} className="form-control form-control-sm" disabled />
                            
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="form-group">
                            <label >Direccion completa</label>
                            <textarea className="form-control form-control-sm" name='address' onChange={handleInputChange2} value={addressData.address}></textarea>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12 mt-2">
                        <div className="form-group">
                            <label >Municipio</label>
                            <select className="form-control form-control-sm" name="city" id='city' onChange={handleInputChange2}  value={addressData.city} disabled>
                                            <option>Seleccione un municipio...</option>
                                            {city.map((city) => (
                                                <option key={city.id} value={city.name}>
                                                    {city.name}
                                                </option>
                                            ))}
                                        </select>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12 mt-2">
                        <div className="form-group">
                            <label >Departamento</label>
                            <select className="form-control form-control-sm" name="department" onChange={handleInputChange} >
                                            <option value="0">Selecciona un departamento</option>
                                            {departments.map((department) => (
                                                <option key={department.id} value={department.id} data-name={department.name}>
                                                    {department.name}
                                                </option>
                                            ))}
                                        </select>
                        </div>
                        <div className="col-4 mt-2">
                        
                    </div>
                    </div>


                    <div className="col-xl-4 col-md-4 col-sm-12 mt-2">
                        <div className="form-group">
                            <label >Pais</label>
                            <input className="form-control form-control-sm" type="text" value="El Salvador" disabled></input>
                        </div>
                    </div>



                </div>
            </div>
        </>


    );
}

export default SaleFormPage03;