import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useData } from '../../../context/dataContext';
import React, { useEffect, useState } from 'react';
import { baseUrl } from "../../../helpers/baseUrl";
import "../css/invoice-list.css";
import "../css/alert.css";
import 'font-awesome/css/font-awesome.min.css';
import configureAxios from "../../../helpers/axiosConfig";
import SwalMsg from "../../../helpers/SwalMsg";

function SaleDetailPage() {
    const axios = configureAxios();
    const user = JSON.parse(sessionStorage.getItem("userData"));
    const [isValid, setIsValid] = useState(true);
    const [errors, setErrors] = useState([]);
    const [frase, setFrase] = useState('');
    const frasesVendedor = [
        "Tu dedicación y pasión por las ventas son la clave de nuestro éxito compartido. ¡Gracias por ser un vendedor excepcional!",
        "Cada venta que registras contribuye al crecimiento de nuestro negocio. ¡Tu trabajo es verdaderamente valioso!",
        "Eres más que un vendedor, eres un embajador de nuestra marca. ¡Gracias por representarnos con profesionalismo y entusiasmo!",
        "Cada transacción que gestionas refleja tu habilidad para construir relaciones sólidas. ¡Sigue creando conexiones valiosas!",
        "Tu enfoque proactivo y dedicación no pasan desapercibidos. ¡Gracias por ir más allá en cada venta!",
        "Eres el motor que impulsa nuestro éxito. ¡Tu compromiso y esfuerzo son invaluables!",
        "Cada venta es una victoria que celebramos contigo. ¡Gracias por ser un miembro destacado de nuestro equipo de ventas!",
        "Tus habilidades de ventas y tu atención a los detalles hacen que cada transacción sea excepcional. ¡Gracias por tu excelencia!",
        "Eres la razón por la que nuestros clientes eligen nuestra marca. ¡Sigue inspirando a través de tu dedicación!",
        "Tus esfuerzos no solo registran ventas, sino que crean experiencias inolvidables para nuestros clientes. ¡Gracias por tu entrega!",
        "Cada venta es una oportunidad para mostrar tu profesionalismo y pasión. ¡Gracias por ser un vendedor ejemplar!",
        "Eres el héroe detrás de cada venta exitosa. ¡Gracias por tu arduo trabajo y determinación!",
        "Tus habilidades y dedicación son una inspiración para todo el equipo. ¡Gracias por elevar constantemente el estándar!",
        "En cada venta, demuestras el verdadero significado de la excelencia en el servicio. ¡Gracias por ser un vendedor excepcional!",
        "Tu capacidad para comprender las necesidades del cliente es una habilidad excepcional. ¡Gracias por tu empatía y atención!",
        "Eres más que un vendedor, eres un socio valioso en nuestro éxito continuo. ¡Gracias por tu compromiso y contribución!",
        "Tu actitud positiva y profesionalismo hacen que cada interacción sea memorable. ¡Gracias por ser un vendedor estelar!",
        "Cada venta es un testimonio de tu habilidad para cerrar tratos con maestría. ¡Sigue alcanzando nuevas alturas!",
        "Eres una parte esencial de nuestro equipo de ventas. ¡Gracias por tu dedicación y por marcar la diferencia cada día!",
        "Tu compromiso con la excelencia en ventas es evidente en cada transacción. ¡Gracias por ser un vendedor ejemplar!",
    ];

    useEffect(() => {
        generateFrase();
    }, []);

    useEffect(() => {
        const errors = validateData();
        if (errors.length === 0) {
            setIsValid(true);
        } else {
            setErrors(errors);
            setIsValid(false);
        }
    }, []);

    const generateFrase = () => {
        const id = Math.floor(Math.random() * frasesVendedor.length);
        setFrase(frasesVendedor[id]);
    };

    const validateData = () => {
        let errors = [];
        let msg;
        if (customerData.id_customer === "") {
            msg = "No has seleccionado un cliente";
            errors.push(msg);
        }
        if (orderDetails.delivery_date === "" || addressData.address === "" || addressData.department === "" || addressData.city === "") {
            msg = "No has completado la direccion de entrega";
            let msg2 = "No has agregado una fecha de entrega";
            errors.push(msg);
            errors.push(msg2);
        }

        if (productData.length === 0) {
            msg = "No has seleccionado ningun producto";
            errors.push(msg);
        }

        if (idBusiness.length === 0) {
            msg = "No has seleccionado el negocio al que pertenece esta venta";
            errors.push(msg);
        }

        return errors;
    }


    const navigate = useNavigate();
    const [expandedRows, setExpandedRows] = useState({});
    const currentDate = new Date().toISOString().split('T')[0];
    const { orderDetails, customerData, productData, addressData, advancesData, idBusiness, productImg } = useData();
    const totalGeneral = productData.reduce((total, product) => total + parseFloat(product.total), 0).toFixed(2);

    const [orderData, setOrderData] = useState({
        order: {
            order_no: "CYA-SEP-001" ?? null,
            order_date: currentDate ?? null,
            id_customer: customerData.id_customer ?? null,
            id_seller: user.id_user ?? null,
            delivery_date: orderDetails.delivery_date ?? null,
            payment_method: 1,
            total: totalGeneral ?? null,
            notes: "N/A",
            id_business: idBusiness ?? null,
            id_status: 1
        },
        order_detail: productData ?? null,
        address: addressData ?? null
    });

    const formData = new FormData();

    var no = 1;

    const handleRowClick = (rowIndex) => {
        const updatedRows = { ...expandedRows };
        updatedRows[rowIndex] = !expandedRows[rowIndex];
        setExpandedRows(updatedRows);
    };

    useEffect(() => {

        if (
            advancesData.quantity &&
            advancesData.id_payment_method &&
            advancesData.received_by &&
            advancesData.date_received && 
            advancesData.details
        ) {
            // Si hay datos, actualiza el estado con advancesData
            setOrderData((prevOrderData) => ({
                ...prevOrderData,
                advances: advancesData,
            }));
        }

    }, []);

    const handleSave = async () => {
        if (!isValid) {
            return;
        }

        const detail = productData.forEach(obj => {
            delete obj.name;
            delete obj.description;
            delete obj.details;
        });

        SwalMsg.saving();

        try {

            Object.entries(orderData.order).forEach(([key, value]) => {
                formData.append(`order[${key}]`, value);
            });

            orderData.order_detail.forEach((product, index) => {
                Object.entries(product).forEach(([key, value]) => {
                    formData.append(`order_detail[${index}][${key}]`, value);
                });
            });

            if (orderData.advances) {
                Object.entries(orderData.advances).forEach(([key, value]) => {
                    formData.append(`advances[${key}]`, value);
                });
            }

            Object.entries(orderData.address).forEach(([key, value]) => {
                formData.append(`address[${key}]`, value);
            });

            const response = await axios.post(`${baseUrl.url}sales`, formData);

            if (response.status === 201) {
                SwalMsg.savingImagesOrder();

                const id_order = response.data.id_order;

                productImg.forEach(async (product, index) => {
                    if (product.images && product.images.length > 0) {
                        const formDataTest = new FormData();
                        formDataTest.append('products[id_order]', id_order);
                        formDataTest.append('products[id_product]', product.id_product);
                        product.images.forEach((image) => {
                            formDataTest.append(`products[images]`, image);
                        });
                        const response = await axios.post(`${baseUrl.url}sales/save-images`, formDataTest);
                    }

                })

                Swal.fire({
                    title: '¡Registro guardado!',
                    text: 'La venta se ha registrado exitosamente',
                    icon: 'success',
                    padding: '2em',
                    confirmButtonText: 'Aceptar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/sales/mylist')
                    }
                }
                );
            }
        } catch (error) {
            if (error.response.status === 422) {
                Swal.fire({
                    title: 'Advertencia',
                    text: 'No se pudo crear la venta, verifica que todos los datos esten completos',
                    icon: 'warning',
                    padding: '2em',
                });
            } else {
                SwalMsg.serverError();
                console.log("Ocurrio un error al crear la venta"); 
            }
        }
    }

    return (
        <>
            <div className="layout-px-spacing">
                <div className="middle-content container-xxl p-0">
                    <div className="row invoice layout-top-spacing layout-spacing">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="doc-container">
                                {!isValid && (
                                    <div className="alert alert-light-danger alert-dismissible fade show border-0 mb-4" role="alert">
                                        <strong>Error!</strong> No has completado toda la informacion, has clic en Ver errores para mas detalles
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-xl-9">
                                        <div className="invoice-container">
                                            <div className="invoice-inbox">
                                                <div id="ct" className="">
                                                    <div className="invoice-00001">
                                                        <div className="content-section">
                                                            <div className="inv--head-section inv--detail-section">
                                                                <div className="row">
                                                                    <div className="col-sm-6 col-12 mr-auto">
                                                                        <div className="d-flex">
                                                                            <img className="company-logo" src="/CYA.jpg" alt="company" />
                                                                            <h3 className="in-heading align-self-center"></h3>
                                                                        </div>
                                                                        <p className="inv-street-addr mt-3">Grupo CYA S.A. de C.V.</p>
                                                                        <p className="inv-email-address">contact@grupocyasv.com</p>
                                                                        <p className="inv-email-address">(503) 7745-8329</p>
                                                                    </div>
                                                                    <div className="col-sm-6 text-sm-end">
                                                                        <p className="inv-list-number mt-sm-3 pb-sm-2 mt-4"><span className="inv-title">Nuevo pedido</span> <span className="inv-number"></span></p>
                                                                        <p className="inv-created-date mt-sm-5 mt-3"><span className="inv-title">Fecha de registro : </span> <span className="inv-date">{currentDate}</span></p>
                                                                        <p className="inv-due-date"><span className="inv-title">Fecha de entrega: </span> <span className="inv-date">{orderDetails.delivery_date ? orderDetails.delivery_date : "No seleccionada"}</span></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="inv--detail-section inv--customer-detail-section">
                                                                <div className="row">
                                                                    <div className="col-xl-8 col-lg-7 col-md-6 col-sm-4 align-self-center">
                                                                        <p className="inv-to">Datos del cliente</p>
                                                                    </div>
                                                                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 align-self-center order-sm-0 order-1 text-sm-end mt-sm-0 mt-5">
                                                                        <h6 className=" inv-to">Direccion de entrega</h6>
                                                                    </div>

                                                                    <div className="col-xl-8 col-lg-7 col-md-6 col-sm-4">
                                                                        <p className="inv-customer-name">
                                                                            {
                                                                                customerData.id_customer !== '' ? (
                                                                                    <><p className="inv-customer-name">{customerData.first_name} {customerData.last_name}</p><p className="inv-email-address"> {customerData.email} </p><p className="inv-email-address">{customerData.phone_number} </p></>
                                                                                ) : (
                                                                                    <><p className="inv-customer-name">Sin datos</p></>
                                                                                )
                                                                            }
                                                                        </p>
                                                                    </div>

                                                                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 order-sm-0 order-1 text-sm-end">
                                                                        {
                                                                            addressData.address !== '' ? (
                                                                                <><p className="inv-street-addr">{addressData.address}</p><p className="inv-email-address">{addressData.city}, {addressData.department}</p><p className="inv-email-address">{addressData.country}</p></>
                                                                            ) : (
                                                                                <div className="col-xl-10 col-lg-10">
                                                                                    <><p className="inv-customer-name">Sin datos</p></>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                </div>

                                                            </div>

                                                            <div className="inv--product-table-section">
                                                                <div className="table-responsive">
                                                                    <table className="table">
                                                                        <thead className="">
                                                                            <tr>
                                                                                <th scope='col'>Detalles</th>
                                                                                <th scope="col">No</th>
                                                                                <th scope="col">Producto</th>
                                                                                <th className="text-end" scope="col">Cantidad</th>
                                                                                <th className="text-end" scope="col">Precio</th>
                                                                                <th className="text-end" scope="col">Total</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {productData.length > 0 ? (
                                                                                productData.map((item, rowIndex) => (
                                                                                    <React.Fragment key={item.id}>
                                                                                        <tr onClick={() => handleRowClick(rowIndex)}>
                                                                                            <td>
                                                                                                {expandedRows[rowIndex] ? (
                                                                                                    <i className="fa fa-eye-slash fa-ms"></i>

                                                                                                ) : (
                                                                                                    <i className="fa fa-eye fa-ms"></i>
                                                                                                )}
                                                                                            </td>
                                                                                            <td>{no}</td>
                                                                                            <td>{item.name}</td>
                                                                                            <td className="text-end">{item.quantity}</td>
                                                                                            <td className="text-end">${item.price}</td>
                                                                                            <td className="text-end">${item.total}</td>
                                                                                        </tr>
                                                                                        {expandedRows[rowIndex] && (
                                                                                            <tr>
                                                                                                <td colSpan="6">
                                                                                                    {item.aditional_details}
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                ))
                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan="6" className='text-center'>No has seleccionado ningun producto</td>
                                                                                </tr>
                                                                            )}




                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                            <div className="inv--total-amounts">

                                                                <div className="row mt-4">
                                                                    <div className="col-sm-5 col-12 order-sm-0 order-1">
                                                                    </div>
                                                                    <div className="col-sm-7 col-12 order-sm-1 order-0">
                                                                        <div className="text-sm-end">
                                                                            <div className="row">
                                                                                <div className="col-sm-8 col-7">
                                                                                    <p className="">Sub Total :</p>
                                                                                </div>
                                                                                <div className="col-sm-4 col-5">
                                                                                    <p className="">${totalGeneral}</p>
                                                                                </div>
                                                                                <div className="col-sm-8 col-7">
                                                                                    <p className="">Impuesto 0% :</p>
                                                                                </div>
                                                                                <div className="col-sm-4 col-5">
                                                                                    <p className="">$0.00</p>
                                                                                </div>
                                                                                <div className="col-sm-8 col-7 grand-total-title mt-3">
                                                                                    <h4 className="" style={{ color: '#000' }}>Grand Total : </h4>
                                                                                </div>
                                                                                <div className="col-sm-4 col-5 grand-total-amount mt-3">
                                                                                    <h4 className="" style={{ color: '#000' }}>${totalGeneral}</h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="inv--note">

                                                                <div className="row mt-4">
                                                                    <div className="col-sm-12 col-12 order-sm-0 order-1">
                                                                        <p>{frase}</p>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>


                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-xl-3">

                                        <div className="invoice-actions-btn">

                                            <div className="invoice-action-btn">

                                                <div className="row">
                                                    <div className="col-xl-12 col-md-3 col-sm-6">
                                                        <a className="btn btn-primary btn-send _effect--ripple waves-effect waves-light" onClick={handleSave} disabled={!isValid}>Guardar</a>
                                                    </div>
                                                    <div className="col-xl-12 col-md-3 col-sm-6">
                                                        <a className="btn btn-success btn-download _effect--ripple waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#modalErrors">Ver errores</a>
                                                    </div>
                                                    <div className="col-xl-12 col-md-3 col-sm-6 mb-4">
                                                        <Link to="/sales/new">
                                                            <a href="./app-invoice-edit.html" className="btn btn-info btn-edit _effect--ripple waves-effect waves-light">Empezar</a>
                                                        </Link>
                                                    </div>
                                                    <div className="col-xl-12 col-md-3 col-sm-6">
                                                        <Link to="/sales/mylist">
                                                            <a href="./app-invoice-edit.html" className="btn btn-danger btn-edit _effect--ripple waves-effect waves-light">Cancelar</a>
                                                        </Link>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

            <div className="modal fade" id="modalErrors" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Detalle de errores</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <svg> ... </svg>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul>
                                {errors.map((error, i) => (
                                    <li key={i}>{error}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn btn-danger" data-bs-dismiss="modal"><i className="flaticon-cancel-12"></i> Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SaleDetailPage;