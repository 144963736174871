import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import "./css/account-setting.css";
import "./css/tabs.css";
import { useFormik } from 'formik';
import { baseUrl } from "../../helpers/baseUrl";
import Swal from 'sweetalert2';
import { customerSchema } from "../../validation/customerSchema";
import configureAxios from "../../helpers/axiosConfig";
import Breadcrumb from "../../components/Breadcrumb";
import SwalMsg from "../../helpers/SwalMsg";

function CustomerFormPage() {
    const user = JSON.parse(sessionStorage.getItem("userData")) ?? null;
    const axios = configureAxios();
    const { id } = useParams();
    const initialStateCustomer = {
        first_name: '',
        second_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        created_by: user.id_user
    }
    const [customerData, setCustomerData] = useState(initialStateCustomer);

    const formik = useFormik({
        initialValues: initialStateCustomer,
        validationSchema: customerSchema,
        onSubmit: (values) => {
            handleSubmit();
        },
    });

    useEffect(() => {
        if (id !== undefined) {
            getCustomer();
        }
    }, []);

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;

        setCustomerData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        formik.handleChange(e);
    };


    const handleBlur = (e) => {
        formik.handleBlur(e);
    };

    const handleSubmit = async () => {

        if (id !== undefined) {
            handleUpdate();
        } else {
            handleSave();
        }
    }

    const handleSave = async () => {

        const data = {
            customer: customerData,
            action: 'create'
        }

        SwalMsg.saving();

        try {
            const response = await axios.post(`${baseUrl.url}customers`, data, {
                timeout: 10000,
            });

            if (response && response.status === 201) {
                Swal.fire({
                    title: '¡Registro guardado!',
                    text: 'El cliente se ha creado exitosamente',
                    icon: 'success',
                    padding: '2em',
                    confirmButtonText: 'Aceptar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/customers/list');
                    }
                }
                );
            }

        } catch (error) {
            console.log(error);
            if (error && error.response && error.response.status === 422) {

                SwalMsg.warning('No se pudo crear el cliente, verifique que todos los datos esten completos');

            } else {
                SwalMsg.serverError();
            }
        }
    }

    const handleUpdate = async () => {

        const data = {
            customer: customerData,
            action: 'update'
        }

        Swal.fire({
            title: 'Actualizando...',
            text: 'Por favor espera',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        });


        try {
            const response = await axios.put(`${baseUrl.url}customers/${id}`, data, {
                timeout: 10000,
            });

            if (response.status === 200) {
                Swal.fire({
                    title: '¡Registro actualizado!',
                    text: 'El cliente se ha actualizado exitosamente',
                    icon: 'success',
                    padding: '2em',
                    confirmButtonText: 'Aceptar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/customers/list');
                    }
                }
                );
            }

        } catch (error) {
            console.log(error.response.data);
            if (error.response.status === 422) {
                Swal.fire({
                    title: 'Advertencia',
                    text: 'No se pudo actualizar el cliente, verifique que todos los datos esten completos',
                    icon: 'warning',
                    padding: '2em',
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Ocurrió un error en el servidor',
                    icon: 'error',
                    padding: '2em',
                });
            }
        }
    }

    const getCustomer = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}customers/find/${id}`);
            const { id_customer, ...data } = response.data.data[0];
            data.updated_by = user.id_user;
            setCustomerData(data);
            formik.setValues(data);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="layout-px-spacing">
            <div className="middle-content container-xxl p-0">
                <div className="page-meta">
                    <Breadcrumb options={["Usuarios", "Clientes", id === undefined ? "Nuevo cliente" : "Editar cliente"]} />
                </div>
                <div className="account-settings-container layout-top-spacing">
                    <div className="account-content">
                        <div className="tab-content" id="animateLineContent-4">
                            <div className="tab-pane fade show active" id="animated-underline-home" role="tabpanel" aria-labelledby="animated-underline-home-tab">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                                        <form className="section general-info">
                                            <div className="info">
                                                <h6 className="">Informacion del cliente</h6>
                                                <div className="row">
                                                    <div className="col-lg-11 mx-auto">
                                                        <div className="row">
                                                            <div className="col-xl-2 col-lg-12 col-md-4">
                                                                <div className="profile-image mt-4 pe-md-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4">
                                                                <div className="form">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="fullName">Primer nombre</label>
                                                                                <input type="text" className="form-control mb-3" name="first_name" value={customerData.first_name} onChange={handleChange} onBlur={handleBlur} />
                                                                                {formik.touched.first_name && formik.errors.first_name ? (
                                                                                    <div class="alert alert-danger alert-dismissible fade show border-0 mb-4" role="alert">
                                                                                        {formik.errors.first_name}
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="fullName">Segundo nombre</label>
                                                                                <input type="text" className="form-control mb-3" name="second_name" value={customerData.second_name} onChange={handleChange} onBlur={handleBlur} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="fullName">Apellido</label>
                                                                                <input type="text" className="form-control mb-3" name="last_name" value={customerData.last_name} onChange={handleChange} onBlur={handleBlur} />
                                                                                {formik.touched.last_name && formik.errors.last_name ? (
                                                                                    <div class="alert alert-danger alert-dismissible fade show border-0" role="alert">
                                                                                        {formik.errors.last_name}
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="email">Correo</label>
                                                                                <input type="email" className="form-control mb-3" name="email" value={customerData.email} onChange={handleChange} onBlur={handleBlur} />
                                                                                {formik.touched.email && formik.errors.email ? (
                                                                                    <div className="alert alert-danger alert-dismissible fade show border-0" role="alert" onBlur={handleBlur}>
                                                                                        {formik.errors.email}
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="phone">Telefono</label>
                                                                                <input type="number" className="form-control mb-3" name="phone_number" value={customerData.phone_number} onChange={handleChange} onBlur={handleBlur} />
                                                                                {formik.touched.phone_number && formik.errors.phone_number ? (
                                                                                    <div class="alert alert-danger alert-dismissible fade show border-0 mb-4" role="alert">
                                                                                        {formik.errors.phone_number}
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-12 d-flex justify-content-between">
                                                                            <div className="form-group text-start">
                                                                                <Link to="/customers">
                                                                                    <button className="btn btn-danger"> Cancelar </button>
                                                                                </Link>
                                                                            </div>
                                                                            <div className="form-group text-end">
                                                                                <button type="button" onClick={formik.handleSubmit} className="btn btn-secondary _effect--ripple waves-effect waves-light">{id !== undefined ? "Actualizar" : "Guardar"}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerFormPage; 