import axios from 'axios';

const configureAxios = () => {
  const user = JSON.parse(sessionStorage.getItem("userData"));

  // Verifica si hay un usuario y un token en el sessionStorage
  if (user && user.token) {
    const token = user.token;

    const instance = axios.create();

    // Configura las cabeceras solo si hay un token presente
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return instance;
  } else {
    // Si no hay un usuario o token, simplemente devuelve la instancia de axios sin configurar
    return axios.create();
  }
};

export default configureAxios;

