import React, { useEffect, useState, useRef } from 'react';
import 'select2';
import debounce from 'lodash/debounce';
import "../css/search.css";
import { useFormik } from 'formik';
import { FilePond, registerPlugin } from 'react-filepond';
import { productDetailSchema } from "../../../validation/productdetailSchema";
import { baseUrl } from "../../../helpers/baseUrl";
import { useData } from '../../../context/dataContext';
import configureAxios from "../../../helpers/axiosConfig";
import "../css/card_custom.css";
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
registerPlugin(FilePondPluginImagePreview);

function SaleFormPage02() {
    const axios = configureAxios();
    const [value, setValue] = useState('');
    const { productData, addProduct, removeProduct, addProductImg } = useData();
    const [productImg, setProductImg] = useState({
        id_product: 0, 
        images: []
    });
    const [listView, setListView] = useState(false);
    const [products, setProducts] = useState();
    const [isVisible, setIsVisible] = useState(false);
    const [viewDetails, setViewDetails] = useState(false);
    const [product, setProduct] = useState([]);

    const handleChangeView = (status) => {
        setListView(status);
    }

    useEffect(() => {
        if (typeof value === 'string' && value.trim() !== '' || value.length > 0) {
            obtenerDatosDesdeAPI(value);
        } else {
            setIsVisible(false);
            setProducts([]);
        }

    }, [value]);

    const formik = useFormik({
        initialValues: product,
        validationSchema: productDetailSchema,
        onSubmit: (values) => {
            handleAdd();
        },
    });

    const handleBlur = (e) => {
        formik.handleBlur(e);
    };

    // Se ejecuta cada ves que el usuario escribe en el input de busqueda y obtiene los productos que coinciden
    const obtenerDatosDesdeAPI = debounce(async (value) => {
        try {
            const response = await axios.get(`${baseUrl.url}products/search`, {
                params: {
                    name: value,
                },
            });
            const data = response.data.data;
            if (Array.isArray(data)) {
                setProducts(data);
                setIsVisible(true);
            } else {
                console.error("La respuesta de la API no es un array válido.");
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    // Cambia el valor del nombre del producto a buscar por cada cambio en el input
    const handleInputChange = (newValue) => {
        setValue(newValue);
    }

    // Se ejecuta cuando el usuario selecciona un producto
    const handleItemClick = (product) => {
        setProduct(product);

        setProductImg((prevProductItem) => ({
            ...prevProductItem,
            id_product: product.id_product
        }));

        formik.setValues(product);
        setIsVisible(false);
        setViewDetails(true);
        setValue('');
        document.getElementById("input-search").disabled = true;
    };


    // Agrega el producto al contexto global
    const handleAdd = () => {
        setViewDetails(false);
        document.getElementById("input-search").disabled = false;
        const total = (Number(product.quantity) * Number(product.price)).toFixed(2);
        setProduct((prevProductItem) => ({
            ...prevProductItem,
            total: total
        }));

        addProductImg(productImg); 

        setProductImg({id_product: 0, images: []});

        addProduct(
            {
                ...product,
                total: total
            }
        );
    }

    // Establece las nuevas propiedades del objeto producto, como cantidad o detalles
    const handleChange = (event) => {
        const { name, value } = event.target;

        setProduct((prevProductItem) => ({
            ...prevProductItem,
            [name]: value
        }));
        formik.handleChange(event);
    };

    // Limpia el cuadro de inputs y permite buscar y seleccionar otro producto
    const handleClean = () => {
        document.getElementById("input-search").disabled = false;
        setViewDetails(false);
        setProduct([]);
        formik.setValues([]);
    }

    // Elimina un producto del estado global
    const handleDelete = (product) => {
        console.log("Producto eliminado");
        removeProduct(product);
    }

    return (
        <>
            <div>
                <div className="row mb-4">
                    <div className="col-12">
                        <form className="form-inline my-2 my-lg-0 justify-content-center">
                            <div className="w-100">
                                <input type="text" value={value} onChange={(e) => handleInputChange(e.target.value)} className="form-control product-search br-30" id="input-search" placeholder="Buscar productos..." />
                            </div>
                        </form>

                        {isVisible ? (
                            <div className="searchable-container mt-4" style={{ width: '100%' }}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="searchable-items">
                                            {products.map(result => (
                                                <div className="items" onClick={() => handleItemClick(result)} key={result.id_product}>
                                                    <div className="user-name">
                                                        <p className="">{result.name}</p>
                                                    </div>
                                                    <div className="user-email">
                                                        <p>{result.description.slice(0, 40)}...</p>
                                                    </div>
                                                    <div className="user-status text-center">
                                                        <p>$ {result.price}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <ul>

                                </ul>
                            </div>
                        ) : (
                            <h1></h1>
                        )}


                        {viewDetails ? (
                            <>
                                <div className="row mt-4">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <label htmlFor="">Nombre del producto</label>
                                        <input type="text" id='search-product' className="form-control form-control-sm mb-2" value={product.name} disabled />
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <label htmlFor="">Cantidad</label>
                                        <input type="text" className="form-control form-control-sm" name='quantity' onChange={handleChange} value={product.quantity} onBlur={handleBlur} />
                                        {formik.touched.quantity && formik.errors.quantity ? (
                                            <div className="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                {formik.errors.quantity}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <label htmlFor="">Precio</label>
                                        <input type="text" className="form-control form-control-sm" name='price' onChange={handleChange} value={product.price} onBlur={handleBlur} />
                                        {formik.touched.price && formik.errors.price ? (
                                            <div className="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                {formik.errors.price}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                        <textarea className='form-control form-control-sm mt-2' placeholder='Detalles' name='aditional_details' rows="3" onChange={handleChange} value={product.aditional_details} onBlur={handleBlur}></textarea>
                                        {formik.touched.aditional_details && formik.errors.aditional_details ? (
                                            <div className="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                {formik.errors.aditional_details}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 mt-2">
                                        <FilePond
                                            files={productImg.images}
                                            allowMultiple={false}
                                            onupdatefiles={(fileItems) => {
                                                setProductImg((prevData) => ({
                                                    ...prevData,
                                                    images: fileItems.map((fileItem) => fileItem.file),
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="form-group mt-4 d-flex justify-content-between">
                                    <button type='button' className='btn btn-primary btn-sm d-sm-block' onClick={formik.handleSubmit}>Agregar</button>
                                    <button className='btn btn-outline-danger btn-sm d-sm-block' onClick={handleClean}>Buscar otro producto</button>
                                </div>

                            </>
                        ) : (
                            <h1></h1>
                        )}

                    </div>
                </div>
            </div>

            <div className="options">
                <a onClick={() => handleChangeView(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                </a>
                <span> | </span>
                <a onClick={() => handleChangeView(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                </a>
            </div>

            {listView ? (

                productData.length > 0 ? (
                    productData.map((product) => (
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <img src="" alt="" />
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <h6 className="card-title">{product.name}</h6>
                                        <div className="d-flex justify-content-between">
                                            <div className="text">
                                                Cantidad: {product.quantity}
                                            </div>
                                            <div className="text">
                                                Precio: $ {product.price}
                                            </div>
                                            <div className="text">
                                                Total: $ {product.total}
                                            </div>
                                        </div>
                                        <div className="detalles mt-2">
                                            <p className="card-text">{product.aditional_details ? product.aditional_details : "No has agregado detalles"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <tr className='text-center'>
                        <td colSpan="6" >No has agregado ningun producto</td>
                    </tr>
                )

            ) : (
                <div className="table-responsive mt-4">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="checkbox-area text-center " scope="col">
                                    <div className="form-check form-check-primary">
                                        <input className="form-check-input" id="noBorder_parent_all" type="checkbox" />
                                    </div>
                                </th>
                                <th className="" scope="col">Producto</th>
                                <th className="text-center " scope="col">Cantidad</th>
                                <th className="text-center " scope="col">Precio</th>
                                <th className="text-center " scope="col">Total</th>
                                <th className="text-center " scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {productData.length > 0 ? (
                                productData.map((product) => (
                                    <tr key={product.id_product}>
                                        <td className='text-center '>
                                            <div className="form-check form-check-primary">
                                                <input className="form-check-input noBorder_child" type="checkbox" />
                                            </div>
                                        </td>
                                        <td className="">{product.name}</td>
                                        <td>

                                            <span className="table-inner-text">{product.quantity}</span>
                                        </td>
                                        <td className="text-center">${product.price}</td>
                                        <td className="text-center">${product.total}</td>
                                        <td className="text-center" onClick={() => handleDelete(product)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x-circle table-cancel"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr className='text-center'>
                                    <td colSpan="6" >No has agregado ningun producto</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>
            )}






        </>

    );

}

export default SaleFormPage02; 