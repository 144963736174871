import Swal from 'sweetalert2';
import {  useNavigate } from 'react-router-dom';

const SwalMsg = {
    saving: () => {
        Swal.fire({
            title: 'Guardando...',
            text: 'Por favor espera',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        });
    },

    savingImagesOrder: () => {
        Swal.fire({
            title: 'Guardando imagenes...',
            text: 'Venta creada, guardando imagenes, por favor espera...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        });
    },

    SaveSuccess: (text, navigateRoute) => {
        const navigate = useNavigate();

        Swal.fire({
            title: '¡Registro guardado!',
            text: text,
            icon: 'success',
            padding: '2em',
            confirmButtonText: 'Aceptar',
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(navigateRoute);
            }
        }
        );
    },
    
    warning: (text) => {
        Swal.fire({
            title: 'Advertencia',
            text: text,
            icon: 'warning',
            padding: '2em',
        });
    },

    serverError: () => {
        Swal.fire({
            title: 'Error',
            text: 'Ocurrió un error en el servidor, contacta a soporte de inmediato',
            icon: 'error',
            padding: '2em',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Soporte',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                window.open('https://api.whatsapp.com/send?phone=50372179594', '_blank');
            }
        });
    }
};

export default SwalMsg;