import * as Yup from 'yup';

export const UserSchema = Yup.object({
    username: Yup.string().min(6, 'El nombre de usuario debe tener al menos 8 caracteres').required('El nombre de usuario es obligatorio'),
    first_name: Yup.string().min(3, 'El nombre debe tener al menos 3 caracteres').required('El nombre es obligatorio'),
    last_name: Yup.string().min(3, 'El apellido debe tener al menos 3 caracteres').required('El apellido es obligatorio'),
    email: Yup.string().email('Formato de correo no válido').required('El correo es obligatorio'),
    password: Yup.string()
        .min(8, 'La contraseña debe tener al menos 8 caracteres')
        .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
            'La contraseña debe contener al menos una letra mayúscula, una letra minúscula, un número y un símbolo'
        )
        .required('La contraseña es obligatoria'),
});
