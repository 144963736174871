import "./css/dt-global_style.css";
import "./css/invoice-list.css";
import "./css/datatables.css";
import $, { data } from 'jquery';
import 'datatables.net';
import { useEffect, useState, useRef } from 'react';
import flatpickr from 'flatpickr';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { baseUrl } from "../../helpers/baseUrl";
import { useData } from '../../context/dataContext';
import configureAxios from "../../helpers/axiosConfig";
import DataTableBase from '../../helpers/DataTableBase';
import Breadcrumb from "../../components/Breadcrumb";
import SwalMsg from "../../helpers/SwalMsg";

function SaleListPage() {
    const axios = configureAxios();
    const navigate = useNavigate();
    const [sales, setSales] = useState([]);
    const [optionSelected, setOptionSelected] = useState(1);
    const [pending, setPending] = useState(true);
    const columns = [
        { name: 'Venta', selector: (row) => <div>No. {row.id_order}</div>, sortable: true, },
        { name: 'Cliente', selector: (row) => <div>{row.customer_first_name} {row.customer_last_name}</div>, sortable: true, },
        { name: 'Vendedor', selector: (row) => <div>{row.seller_first_name} {row.seller_last_name}</div>, sortable: true, },
        {
            name: 'Fecha creada', selector: (row) => row.order_date, sortable: true, width: '140px', cell:
                (row) => (
                    <span className="inv-date">
                        {row.order_date}
                    </span>
                )
        },
        {
            name: 'Fecha de entrega', selector: (row) => row.fechaEntrega, sortable: true, width: '180px', cell:
                (row) => (
                    <span className="inv-date">

                        {row.delivery_date}
                    </span>
                )
        },
        {
            name: 'Estado Pedido', selector: (row) => row.status, sortable: true, width: '150px', cell:
                (row) => (
                    <span
                        className={`badge ${row.status === 'REGISTRADO'
                            ? 'badge-primary'
                            : row.status === 'EN PRODUCCION'
                                ? 'badge-info'
                                : row.status === 'FINALIZADA'
                                    ? 'badge-success'
                                    : row.status === 'CANCELADA'
                                        ? 'badge-danger'
                                        : 'badge-secondary'
                            }`}
                    >
                        {row.status}
                    </span>
                )
        },
        {
            name: 'Estado pago', selector: (row) => row.estado_pago, sortable: true, width: '150px', cell:
                (row) => (
                    <span
                        className={`badge ${row.payment_status === "PENDIENTE"
                            ? "badge-warning"
                            : row.payment_status === "PAGADO"
                                ? "badge-success"
                                : row.payment_status === "CANCELADO"
                                    ? "badge-danger"
                                    : "badge-secondary"
                            }`}
                    >
                        {row.payment_status}
                    </span>
                )
        },
        { name: 'Monto', selector: (row) => "$ " + row.total, sortable: true, },
        {
            name: 'Anticipo', selector: (row) => row.has_advance, sortable: true, width: '120px', cell:
                (row) => (
                    <span
                        className={`badge ${row.has_advance ? "badge-success" : "badge-danger"
                            }`}
                    >
                        {row.has_advance ? "SI" : "NO"}
                    </span>
                )
        },
        {
            name: 'Acciones',

            selector: (row) => row.acciones,
            cell: row => (
                <>
                    <Link to={`/sales/view/${row.id_order}`}>
                        <a className="badge badge-light-primary text-start me-2 action-edit" href="javascript:void(0);">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                        </a>
                    </Link>
                    <a className="badge badge-light-danger text-start action-delete" onClick={handleDeleteModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                    </a>
                </>
            ),
            width: '150px',
        },
    ];

    const handleDeleteModal = () => {
        Swal.fire({
            title: '¿Eliminar venta?',
            text: '¿Estás seguro que deseas eliminar esta venta? ¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sí, eliminar',
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire('No se pudo eliminar', 'Esta venta no se puede eliminar, por favor, cambia su estado a CANCELADA y especifica un motivo de su eliminacion', 'warning');
            }
        });
    };


    const [date, setDate] = useState({
        start: "",
        end: ""
    });
    const { clearContext } = useData();

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        clearContext();
    }, []);


    const fetchData = async (status = 1) => {
        setOptionSelected(status);
        try {
            const response = await axios.get(`${baseUrl.url}sales?status=${status}`);
            if (response.status === 200) {
                setPending(false);
                setSales(response.data.data);
            }
            if (response.status === 204) {
                console.log("No se encontraron registros de ventas para el estado: " + status);
                setSales([]);
            }
        } catch (error) {
            SwalMsg.serverError();
            console.error('Error al obtener los datos de las ventas');
        }
    }

    // Se ejecuta al presionar filtrar en el boton, y obtiene las ventas filtradas por fechas
    const handleFilter = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}sales/filter?start=${date.start}&end=${date.end}`);
            if (response.status === 200) {
                setSales(response.data.data);
            }
        } catch (error) {
            SwalMsg.serverError();
            console.error('Error al obtener los datos de las ventas');
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setDate((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className="layout-px-spacing">
            <div className="middle-content container-xxl p-0">
                <div className="page-meta">
                    <Breadcrumb options={["Ventas", "Ventas", "Ventas Generales"]} />
                </div>
                <div className="row" id="cancel-row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing">
                        <div className="widget-content widget-content-table br-8">
                            <div id="invoice-list_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                                <div className="inv-list-top-section">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 gap-2 d-flex justify-content-md-start justify-content-center flex-wrap options">
                                            <div className="dt-action-buttons align-self-center">
                                                <div className="dt-buttons">
                                                    <Link to="/sales/new">
                                                        <button className="dt-button btn btn-primary _effect--ripple waves-effect waves-light" tabIndex="0" aria-controls="invoice-list"><span>Nueva venta</span></button>
                                                    </Link>
                                                </div>
                                            </div>
                                            {/* <div className="dt-action-buttons align-self-center mx-2">
                                                <div className="dt-buttons">
                                                    <button data-bs-toggle="modal" data-bs-target="#exampleModal" className="dt-button btn btn-outline-primary _effect--ripple waves-effect waves-light" tabIndex="0" aria-controls="invoice-list">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-sliders"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>
                                                    </button>
                                                </div>
                                            </div> */}
                                            <div className="dt-action-buttons align-self-center">
                                                <div className="dt-buttons">
                                                    <button onClick={() => fetchData(1)} className={`btn ${optionSelected === 1 ? 'btn-primary' : 'btn-outline-primary'} _effect--ripple waves-effect waves-light`} tabIndex="0" aria-controls="invoice-list"><span>Registradas</span></button>
                                                </div>
                                            </div>
                                            <div className="dt-action-buttons align-self-center">
                                                <div className="dt-buttons">
                                                    <button onClick={() => fetchData(2)} className={`btn ${optionSelected === 2 ? 'btn-secondary' : 'btn-outline-secondary'} _effect--ripple waves-effect waves-light ${optionSelected === 2 ? 'active' : ''}`} tabIndex="0" aria-controls="invoice-list"><span>En produccion</span></button>
                                                </div>
                                            </div>
                                            <div className="dt-action-buttons align-self-center">
                                                <div className="dt-buttons">
                                                    <button onClick={() => fetchData(3)} className={`btn ${optionSelected === 3 ? 'btn-success' : 'btn-outline-success'} _effect--ripple waves-effect waves-light ${optionSelected === 3 ? 'active' : ''}`} tabIndex="0" aria-controls="invoice-list"><span>Finalizadas</span></button>
                                                </div>
                                            </div>
                                            <div className="dt-action-buttons align-self-center">
                                                <div className="dt-buttons">
                                                    <button onClick={() => fetchData(4)} className={`btn ${optionSelected === 4 ? 'btn-danger' : 'btn-outline-danger'} _effect--ripple waves-effect waves-light ${optionSelected === 4 ? 'active' : ''}`}><span>Canceladas</span></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-sm-12 col-md-12 d-flex justify-content-md-start justify-content-center">
                                                {optionSelected === 1 && (
                                                    <p>Mostrando las ventas registradas</p>
                                                )}
                                                {optionSelected === 2 && (
                                                    <p>Mostrando las ventas en producción</p>
                                                )}
                                                {optionSelected === 3 && (
                                                    <p>Mostrando las ventas finalizadas</p>
                                                )}
                                                {optionSelected === 4 && (
                                                    <p>Mostrando las ventas canceladas</p>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <DataTableBase columns={columns} data={sales} pending={pending} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Filtrar ventas</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <svg> ... </svg>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group mb-4">
                                <label htmlFor="formGroupExampleInput">Fecha de inicio</label>
                                <input type="date" name="start" value={date.start} onInput={handleChange} className="form-control" id="formGroupExampleInput" placeholder="Example input" required />
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="formGroupExampleInput">Fecha final</label>
                                <input type="date" name="end" value={date.end} onInput={handleChange} className="form-control" id="formGroupExampleInput" placeholder="Example input" required />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn btn-light-dark" data-bs-dismiss="modal"><i className="flaticon-cancel-12"></i> Cancelar</button>
                            <button type="button" className="btn btn-primary" onClick={handleFilter} data-bs-dismiss="modal" >Filtrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SaleListPage; 