import "./css/filepond.min.css";
import "./css/FilePondPluginImagePreview.min.css";
import "./css/tagify.css";
import "./css/switches.css";
import "./css/quill.snow.css";
import "./css/custom-tagify.css";
import "./css/custom-filepond.css";
import "./css/ecommerce-create.css";
import React, { useState, useEffect } from 'react';
import 'datatables.net';
import { useFormik } from 'formik';
import { FilePond, registerPlugin } from 'react-filepond';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { baseUrl } from "../../helpers/baseUrl";
import { productSchema } from "../../validation/productSchema";
import configureAxios from "../../helpers/axiosConfig";
import SwalMsg from "../../helpers/SwalMsg"; 

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import Breadcrumb from "../../components/Breadcrumb";
registerPlugin(FilePondPluginImagePreview);

function ProductFormPage() {
    const user = JSON.parse(sessionStorage.getItem("userData")) ?? null;
    const axios = configureAxios();
    const [img, setImg] = useState([]);
    const [product, setProduct] = useState({
        name: '',
        description: '',
        price: 0.00,
        images: [],
    });
    const navigate = useNavigate();
    const { id } = useParams();
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (id !== undefined) {
            setIsEdit(true);
            getProduct();
            getImages();
        }
    }, []);


    const handleBlur = (e) => {
        formik.handleBlur(e);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        setProduct((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        formik.setFieldValue(name, value);

        if (name === 'images') {
            setProduct((prevData) => ({
                ...prevData,
                [name]: e.target.files,
            }));
        }
    };

    const getProduct = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}products/${id}`);
            const { id_product, ...data } = response.data.data;
            data.updated_by = user.id_user;
            setProduct(data);
            formik.setValues(data);
        } catch (error) {
            console.log("Ocurrio un error al obtener la informacion del producto");
        }
    }

    const getImages = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}products/img/${id}`);
            const urls = response.data.data.map(item => item.url);
            setImg(urls);
        } catch (error) {
            console.log("Ocurrio un error al obtener las imagenes del producto");
        }
    }

    const formik = useFormik({
        initialValues: product,
        validationSchema: productSchema,
        onSubmit: (values) => {
            handleSubmit();
        },
    });

    const handleSubmit = async () => {

        if (id !== undefined) {
            handleUpdate();
        } else {
            handleSave();
        }
    }

    const handleSave = async () => {
        const formData = new FormData();

        formData.append('products[name]', product.name);
        formData.append('products[description]', product.description);
        formData.append('products[price]', product.price);
        formData.append('products[created_by]', user.id_user);
        formData.append('action', 'create');

        product.images.forEach((image, index) => {
            formData.append(`products[images]`, image);
        });

        Swal.fire({
            title: 'Guardando...',
            text: 'Por favor espera',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        });

        try {
            const response = await axios.post(`${baseUrl.url}products`, formData);

            if (response.status === 201) {
                Swal.fire({
                    title: '¡Registro guardado!',
                    text: 'El producto se ha creado exitosamente',
                    icon: 'success',
                    padding: '2em',
                    confirmButtonText: 'Aceptar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/products/list');
                    }
                }
                );
            }

        } catch (error) {
            if (error.response && error.response.status === 422) {
                Swal.fire({
                    title: 'Advertencia',
                    text: 'No se pudo crear el producto, verifique que todos los datos esten completos: ' + error.response.data.errors[0],
                    icon: 'warning',
                    padding: '2em',
                });
            } else {
                SwalMsg.serverError(); 
                console.log("Ocurrio un error al crear el producto");
            }
        }
    }

    const handleUpdate = async () => {
        const {cod_product, ...rest} = product;
        const data = {
            products: rest,
            action: 'update'
        }
        Swal.fire({
            title: 'Actualizando...',
            text: 'Por favor espera',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        });
        try {
            const response = await axios.put(`${baseUrl.url}products/${id}`, data, {
                timeout: 5000,
            });

            if (response.status === 200) {
                Swal.fire({
                    title: '¡Registro actualizado!',
                    text: 'El producto se ha actualizado exitosamente',
                    icon: 'success',
                    padding: '2em',
                    confirmButtonText: 'Aceptar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/products/list');
                    }
                }
                );
            }

        } catch (error) {
            if (error.response.status === 422) {
                Swal.fire({
                    title: 'Advertencia',
                    text: 'No se pudo actualizar el producto, verifique que todos los datos esten completos',
                    icon: 'warning',
                    padding: '2em',
                });
            } else {
                SwalMsg.serverError(); 
                console.log("Ocurrio un error al actualizar el producto");
            }
        }
    }


    return (
        <div className="layout-px-spacing">
            <div className="middle-content container-xxl p-0">
                {id === undefined ? (
                    <div className="page-meta">
                        <Breadcrumb options={["Invetario", "Productos", "Nuevo producto"]} />
                    </div>
                ) : (
                    <div className="page-meta">
                        <Breadcrumb options={["Inventario", "Productos", "Editar producto"]} />
                    </div>
                )}


                <div className="row mb-4 layout-spacing layout-top-spacing">

                    <div className="col-xxl-9 col-xl-12 col-lg-12 col-md-12 col-sm-12">

                        <div className="widget-content widget-content-area ecommerce-create-section">

                            <div className="row mb-4">
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" placeholder="Nombre del producto" name="name" value={product.name} onChange={handleChange} onBlur={handleBlur} />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                            {formik.errors.name}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-sm-12">
                                    <label>Descripcion</label>
                                    <textarea className="form-control form-control-sm br-20" rows="8" name="description" value={product.description} onChange={handleChange} onBlur={handleBlur}></textarea>
                                    {formik.touched.description && formik.errors.description ? (
                                        <div className="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                            {formik.errors.description}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            {!isEdit && (
                                <div className="row">
                                    <div className="col-md-8">
                                        <label htmlFor="product-images">Subir imagenes</label>
                                        <div className="multiple-file-upload">
                                            <FilePond
                                                files={product.images}
                                                allowMultiple={true}
                                                onupdatefiles={(fileItems) => {
                                                    setProduct((prevData) => ({
                                                        ...prevData,
                                                        images: fileItems.map((fileItem) => fileItem.file),
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>

                    </div>

                    <div className="col-xxl-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">

                        <div className="row">
                            
                            <div className="col-xxl-12 col-xl-4 col-lg-4 col-md-5 mt-4">
                                <div className="widget-content widget-content-area ecommerce-create-section">
                                    <div className="row">
                                        <div className="col-sm-12 mb-4">
                                            <label htmlFor="regular-price">Precio</label>
                                            <input type="number" className="form-control" name="price" value={product.price} onChange={handleChange} onBlur={handleBlur} />
                                            {formik.touched.price && formik.errors.price ? (
                                                <div className="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                    {formik.errors.price}
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="col-sm-12">
                                            <button type="button" onClick={formik.handleSubmit} className="btn btn-success w-100 _effect--ripple waves-effect waves-light">{isEdit ? "Actualizar Producto" : "Guardar Producto"}</button>
                                            <Link to="/products">
                                                <button className="btn btn-danger w-100 mt-2 _effect--ripple waves-effect waves-light"> Cancelar </button>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductFormPage;