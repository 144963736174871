import { Formik, Field, Form } from 'formik';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from "../../helpers/baseUrl";
import emailSchema from '../../validation/emailSchema';

function ResetPage() {
    const navigate = useNavigate();
    const initialValues = {
        email: ''
    }

    return (
        <div class="auth-container d-flex h-100">

            <div class="container mx-auto align-self-center">

                <div class="row">

                    <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-12 d-flex flex-column align-self-center mx-auto">
                        <div class="card mt-3 mb-3">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-md-12 mb-3">

                                        <h2>Restablecer contraseña</h2>
                                        <p>Introduce tu correo para restablecer tu contraseña</p>

                                    </div>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={emailSchema}
                                        validateOnChange={true}
                                        validateOnBlur={false}
                                        onSubmit={async (values) => {
                                            console.log(values);
                                            try {
                                                const response = await axios.post(`${baseUrl.url}auth/reset-password`, values);
                                                if (response && response.status === 200) {
                                                    Swal.fire({
                                                        title: '¡Solicitud enviada!',
                                                        text: 'Se envio una solicitud de cambio de contraseña, revisa tu correo',
                                                        icon: 'success',
                                                        padding: '2em',
                                                        confirmButtonText: 'Aceptar',
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            navigate('/login')
                                                        }
                                                    }
                                                    );
                                                }
                                            } catch (error) {
                                                Swal.fire({
                                                    title: 'Advertencia',
                                                    text: 'No se pudo restablecer tu contraseña, intentalo de nuevo mas tarde',
                                                    icon: 'warning',
                                                    padding: '2em',
                                                });
                                            }
                                        }}>

                                        {({ handleChange, handleSubmit, errors }) => (

                                            <Form>
                                                <div class="col-md-12">
                                                    <div class="mb-4">
                                                        <label class="form-label">Email</label>
                                                        <Field
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            name="email"
                                                            onChange={handleChange}
                                                        />
                                                        {errors.email ? (
                                                            <div className="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                                {errors.email}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="mb-4">
                                                        <button type='submit' class="btn btn-secondary w-100">RESTABLECER</button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default ResetPage; 