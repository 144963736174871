import React, { createContext, useContext, useState } from 'react';
import { baseUrl } from "../helpers/baseUrl";
import configureAxios from "../helpers/axiosConfig";

const DataContext = createContext();

export function DataProvider({ children }) {
  const axios = configureAxios();
  const [show, setShow] = useState(true);
  const initialStateCustomer = {
    id_customer: '',
    first_name: '',
    second_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  };

  const intialStateAddress = {
    home_number: '',
    address: '',
    city: '',
    department: '',
    country: 'El Salvador',
  }

  const initialStateOrderDetails = {
    order_date: '',
    delivery_date: '',
  }

  const initialStateAdvances = {
    quantity: '',
    id_payment_method: '',
    received_by: '',
    date_received: '',
  }


  const [customerData, setCustomerData] = useState(initialStateCustomer);
  const [productData, setProductData] = useState([]);
  const [addressData, setAddressData] = useState(intialStateAddress);
  const [orderDetails, setOrderDetails] = useState(initialStateOrderDetails);
  const [advancesData, setAdvancesData] = useState(initialStateAdvances);
  const [productImg, setProductImg] = useState([]);
  const [imgUser, setImgUser] = useState([]);
  const [idBusiness, setIdBusiness] = useState([]);
  const [login, setLogin] = useState(null);

  const addProduct = (newProduct) => {
    setProductData([...productData, newProduct]);
  };

  const addProductImg = (newProductImg) => {
    setProductImg([...productImg, newProductImg]);
  };

  const handleChange = () => {
    // SI esta abierta, cerramos el sidebar (Como tienen que quedar las clases cuando esta cerrado)
    if (show) {
      document.getElementById("container").classList.add("sidebar-closed");
      document.getElementById("container").classList.remove("sbar-open");
      document.getElementById("navbar").classList.add("expand-header");
      document.documentElement.classList.remove("sidebar-noneoverflow");
      setShow(false);
    }
    // Si esta cerrada, abrimos el sidebar
    else {
      document.getElementById("container").classList.remove("sidebar-closed");
      document.getElementById("container").classList.add("sbar-open");
      document.getElementById("navbar").classList.remove("expand-header");
      document.documentElement.classList.add("sidebar-noneoverflow");
      setShow(true);
    }
  }

  const getImgUser = async (id) => {
    try {
      const response = await axios.get(`${baseUrl.url}users/img/${id}`);
  
      if (response.data && response.data.data && response.data.data.length > 0 && response.data.data[0].url ) {
        setImgUser(response.data.data[0].url);
      } else {
        setImgUser("https://grupocyasv.nyc3.cdn.digitaloceanspaces.com/img-users/default.png");
      }
    } catch (error) {
      console.error('Error al obtener la imagen del usuario:', error);
      // Puedes manejar el error de acuerdo a tus necesidades.
    }
  };
  

  const updateProduct = (productId, updatedProduct) => {
    const productIndex = productData.findIndex((product) => product.id === productId);

    if (productIndex !== -1) {
      const updatedData = [...productData];
      updatedData[productIndex] = { ...updatedData[productIndex], ...updatedProduct };

      setProductData(updatedData);
    }
  };

  const removeProduct = (productToRemove) => {
    setProductData((prevProductData) =>
      prevProductData.filter((product) => product !== productToRemove)
    );
  };

  const clearContext = () => {
    setCustomerData(initialStateCustomer);
    setProductData([]);
    setAddressData(intialStateAddress);
    setAdvancesData(initialStateAdvances);
    setOrderDetails(initialStateOrderDetails);
    setIdBusiness([]);
    setProductImg([]);
  };

  return (
    <DataContext.Provider value={{
      customerData, setCustomerData,
      productData, addProduct, updateProduct, removeProduct,
      addressData, setAddressData,
      orderDetails, setOrderDetails,
      show, setShow, handleChange,
      imgUser, setImgUser, getImgUser,
      idBusiness, setIdBusiness,
      addProductImg, productImg,
      advancesData, setAdvancesData,
      login, setLogin,
      clearContext
    }}>
      {children}
    </DataContext.Provider>
  );
}

export function useData() {
  return useContext(DataContext);
}