import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { baseUrl } from "../../helpers/baseUrl";
import configureAxios from "../../helpers/axiosConfig";
import DataTableBase from '../../helpers/DataTableBase';
import Breadcrumb from '../../components/Breadcrumb';
import SwalMsg from "../../helpers/SwalMsg";

function ProductListPage() {
    const axios = configureAxios();
    const navigate = useNavigate();
    const [productData, setProductData] = useState([]);
    const columns = [
        { name: 'Codigo', selector: (row) => row.cod_product, sortable: true, sort: true },
        { name: 'Nombre', selector: (row) => row.name, sortable: true, sort: true },
        { name: 'Descripcion', selector: (row) => row.description.slice(0, 20) + "...", sortable: true, sort: true },
        { name: 'Precio', selector: (row) => "$" + row.price, sortable: true, sort: true },
        {
            name: 'Acciones',
            selector: (row) => row.acciones,
            cell: row => (
                <>
                    <Link to={`/products/view/${row.id_product}`} className='badge badge-light-primary text-start me-2 action-edit'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                    </Link>
                    <a onClick={() => handleDeleteModal(row.id_product)} className="badge badge-light-danger text-start action-delete"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg></a>
                </>
            ),
        },
    ];

    const handleDeleteModal = (id) => {
        Swal.fire({
            title: '¿Eliminar producto?',
            text: '¿Estás seguro que deseas eliminar este producto? ¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sí, eliminarlo',
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Eliminando...',
                    text: 'Por favor espera',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                });
                try {
                    const response = await axios.delete(`${baseUrl.url}products/${id}`);

                    if (response.status && response.status === 200) {
                        console.log("Producto eliminado");
                        Swal.fire('Eliminado', 'El registro ha sido eliminado', 'success').then(() => {
                            window.location.reload();
                        });
                    }
                } catch (error) {
                    if (error.response && error.response.status === 400) {
                        Swal.fire('No se pudo eliminar', 'El producto no se puede eliminar porque tiene registros de órdenes de pedidos', 'warning');
                    } else {
                        SwalMsg.serverError();
                    }
                }
            }
        });
    };

    const [newProduct, setNewProduct] = useState({
        products: {
            cod_product: 'COD-001',
            name: '',
            description: '',
            price: '',
        }
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}products`);
            if (response.status === 200) {
                setProductData(response.data.data);
            }
            if (response.status === 204) {
                console.log("No se encontraron registros de productos");
            }

        } catch (error) {
            console.error('Error al obtener el listado de productos');
        }
    };



    return (
        <div className="layout-px-spacing">

            <div className="middle-content container-xxl p-0">
                <div className="page-meta">
                    <Breadcrumb options={["Inventario", "Productos", "Listado de productos"]} />
                </div>
                <div className="row" id="cancel-row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing">
                        <div className="widget-content widget-content-table br-8">
                            <div id="invoice-list_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                                <div className="inv-list-top-section">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center">

                                            <div className="dt-action-buttons align-self-center">
                                                <div className="dt-buttons">
                                                    <Link to="/products/form">
                                                        <button className="dt-button btn btn-primary _effect--ripple waves-effect waves-light" tabIndex="0" aria-controls="invoice-list"><span>Nuevo producto</span></button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <DataTableBase columns={columns} data={productData} />
                            </div>
                        </div>
                    </div>

                </div>

            </div>


            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Eliminar producto</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                
                            </button>
                        </div>
                        <div className="modal-body">

                        </div>
                        <div className="modal-footer">
                            <button className="btn btn btn-light-danger" data-bs-dismiss="modal"><i className="flaticon-cancel-12"></i> Cancelar</button>
                            <button type="button" className="btn btn-primary"> Eliminar </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductListPage; 