import "./css/card_status.css";


function CardStatus({ url, estado, funcion }) {
    return (
        <>
            <a className="card card_status" onClick={funcion}>
                <div className="card_image">
                    <img src={url} alt="" style={{ width: '40px', height: '40px' }} />
                </div>
                <div className="card_text">
                    <p>{estado}</p>
                </div>
            </a>
        </>
    );
}

export default CardStatus;