import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useData } from '../../context/dataContext';
import "./css/account-setting.css";
import "./css/tabs.css";
import axios from 'axios';
import { baseUrl } from "../../helpers/baseUrl";
import Swal from 'sweetalert2';
import "./css/filepond.min.css";
import "./css/FilePondPluginImagePreview.min.css";
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
registerPlugin(FilePondPluginImagePreview);


function UserFormPage() {
    const { id } = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const {setImgUser} = useData(); 
    let count = 0;
    const initialState = {
        first_name: '',
        second_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        images: []
    }

    const [userData, setUserData] = useState(initialState);

    useEffect(() => {
        if (id !== undefined) {
            setIsEdit(true);
            getUser();
        }
    }, []);

    const navigate = useNavigate();


    // ACTUALIZA LA INFORMACION DEL CLIENTE A MEDIDA QUE EL USUARIO DIGITA INFORMACION
    const handleChange = (e) => {
        const { name, value } = e.target;

        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        handleUpdate();

    }



    const handleUpdate = async () => {

        const formData = new FormData();

        formData.append('user[first_name]', userData.first_name);
        formData.append('user[second_name]', userData.second_name);
        formData.append('user[last_name]', userData.last_name);
        formData.append('user[email]', userData.email);
        formData.append('user[phone_number]', userData.phone_number);

        if (userData.images && userData.images.length > 0) {
            userData.images.forEach((image, index) => {
              formData.append(`user[images]`, image);
            });
          }


        try {
            const response = await axios.put(`${baseUrl.url}users/${id}`, formData, {
                timeout: 10000,
            });

            if (response.status === 200) {
                var name = userData.first_name;
                var user = JSON.parse(sessionStorage.getItem('userData')) || {};
                user.name = name;
                sessionStorage.setItem('userData', JSON.stringify(user));

                getImgUser(); 

                Swal.fire({
                    title: '¡Registro actualizado!',
                    text: 'El usuario se ha actualizado exitosamente',
                    icon: 'success',
                    padding: '2em',
                    confirmButtonText: 'Aceptar',

                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                }
                );
            }

        } catch (error) {
            console.log(error);
            /* if (error.response.status === 422) {
                Swal.fire({
                    title: 'Advertencia',
                    text: 'No se pudo actualizar el usuario, verifique que todos los datos esten completos',
                    icon: 'warning',
                    padding: '2em',
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Ocurrió un error en el servidor',
                    icon: 'error',
                    padding: '2em',
                });
            } */
        }
    }

    const getUser = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}users/${id}`);
            const { id_user, ...data } = response.data.data[0];
            console.log(data);
            setUserData(data);
        } catch (error) {
            console.log(error);
        }
    }


    const getImgUser = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}users/img/${id}`);
            console.log(response.data.data[0].url);
            setImgUser(response.data.data[0].url);
        } catch (error) {
            console.log(error);
        }
    }


    /* useEffect(() => {
        console.log(userData);
    }, [userData]); */

    return (
        <div className="layout-px-spacing">
            <div className="middle-content container-xxl p-0">
                <div className="account-settings-container layout-top-spacing">
                    <div className="account-content">
                        <div class="tab-content" id="animateLineContent-4">
                            <div class="tab-pane fade show active" id="animated-underline-home" role="tabpanel" aria-labelledby="animated-underline-home-tab">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                                        <form class="section general-info">
                                            <div class="info">
                                                <h6 class="">Informacion del usuario</h6>
                                                <div class="row">
                                                    <div class="col-lg-11 mx-auto">
                                                        <div class="row">
                                                            <div class="col-xl-2 col-lg-12 col-md-4">
                                                                <div class="profile-image  mt-4 pe-md-4">
                                                                    <div class="img-uploader-content">
                                                                        <FilePond
                                                                            files={userData.images}
                                                                            allowMultiple={false}
                                                                            stylePanelLayout="compact circle"
                                                                            labelIdle="Carga una fotografia"
                                                                            onupdatefiles={(fileItems) => {
                                                                                setUserData((prevData) => ({
                                                                                    ...prevData,
                                                                                    images: fileItems.map((fileItem) => fileItem.file),
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4">
                                                                <div class="form">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label for="fullName">Primer nombre</label>
                                                                                <input type="text" className="form-control mb-3" name="first_name" value={userData.first_name} onChange={handleChange} />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label for="fullName">Segundo nombre</label>
                                                                                <input type="text" className="form-control mb-3" name="second_name" value={userData.second_name} onChange={handleChange} />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label for="fullName">Apellido</label>
                                                                                <input type="text" className="form-control mb-3" name="last_name" value={userData.last_name} onChange={handleChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label for="email">Correo</label>
                                                                                <input type="email" className="form-control mb-3" name="email" value={userData.email} onChange={handleChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label for="phone">Telefono</label>
                                                                                <input type="number" className="form-control mb-3" name="phone_number" value={userData.phone_number} onChange={handleChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-12 d-flex justify-content-between" >
                                                                            <div className="form-group text-start">
                                                                                <Link to="/home">
                                                                                    <button className="btn btn-danger"> Cancelar </button>
                                                                                </Link>

                                                                            </div>
                                                                            <div class="form-group text-end">
                                                                                <button onClick={handleSubmit} class="btn btn-secondary _effect--ripple waves-effect waves-light">{id !== undefined ? "Actualizar" : "Guardar"}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserFormPage; 