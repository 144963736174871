import * as Yup from 'yup';

const SignUpSchema = Yup.object().shape({
    username: Yup.string()
        .min(6, 'El nombre de usuario debe tener al menos 6 caracteres')
        .required('El nombre de usuario es obligatorio'),

    first_name: Yup.string()
        .required('El nombre es obligatorio'),

    last_name: Yup.string()
        .required('El apellido es obligatorio'),

    email: Yup.string()
        .email('Introduce un correo electrónico válido')
        .required('El correo electrónico es obligatorio'),

    password: Yup.string()
        .min(8, 'La contraseña debe tener al menos 8 caracteres')
        .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
            'La contraseña debe incluir al menos una letra mayúscula, un número y un símbolo'
        )
        .required('La contraseña es obligatoria'),
});

export default SignUpSchema;