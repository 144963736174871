import { Link, NavLink } from 'react-router-dom';
import { useState } from 'react';
import { useData } from '../context/dataContext';

function Sidebar() {
    const user = JSON.parse(sessionStorage.getItem("userData"));
    const role = user ? user.role : "null";

    const { handleChange, imgUser } = useData();

    return (
        <div className="sidebar-wrapper sidebar-theme">

            <nav id="sidebar">

                <div className="navbar-nav theme-brand flex-row  text-center">
                    <div className="nav-logo">
                        <div className="nav-item theme-logo">
                            <a href="./index.html">
                                <img src="https://grupocyasv.nyc3.cdn.digitaloceanspaces.com/logos/CYA.jpg" className="navbar-logo" alt="logo" />
                            </a>
                        </div>
                        <div className="nav-item theme-text">
                            <a href="./index.html" className="nav-link"> Modulos </a>
                        </div>
                    </div>
                    <div className="nav-item sidebar-toggle">
                        <div className="btn-toggle sidebarCollapse" onClick={handleChange}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevrons-left"><polyline points="11 17 6 12 11 7"></polyline><polyline points="18 17 13 12 18 7"></polyline></svg>
                        </div>
                    </div>
                </div>

                <div className="profile-info">
                    <div className="user-info">
                        <div className="profile-img">
                            <img src={imgUser} alt="avatar" />
                        </div>
                        <div className="profile-content">
                            <h6 className="">{user ? user.name : ""}</h6>
                            <p className="">{user ? user.role : ""}</p>
                        </div>
                    </div>
                </div>

                <div className="shadow-bottom"></div>
                <ul className="list-unstyled menu-categories ps ps--active-y" id="accordionExample">
                    <li className="menu">
                        <a href="#dashboard" data-bs-toggle="collapse" aria-expanded="true" className="dropdown-toggle collapsed">
                            <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                <span>Inicio</span>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div>
                        </a>
                        <ul className="collapse submenu list-unstyled show" id="dashboard" data-bs-parent="#accordionExample">
                            <li>
                                <NavLink to="/home">
                                    {role === "ADMIN" ? "Analiticas" : "Bienvenido"}
                                </NavLink>
                            </li>
                            
                        </ul>
                    </li>



                    {role !== "INVITADO" && (
                        <><li className="menu">
                            <a href="#users" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle collapsed">
                                <div className="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                                    <span>Usuarios</span>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                                </div>
                            </a>
                            <ul className="collapse submenu list-unstyled" id="users" data-bs-parent="#accordionExample">
                                {role == "ADMIN" && (
                                    <li>
                                        <NavLink to="/users">
                                            Usuarios
                                        </NavLink>
                                    </li>
                                )}
                                <li>
                                    <NavLink to="/customers">
                                        Clientes
                                    </NavLink>
                                </li>
                            </ul>
                        </li><li className="menu">
                                <a href="#sales" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle collapsed">
                                    <div className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                        <span>Ventas</span>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                                    </div>
                                </a>
                                <ul className="collapse submenu list-unstyled" id="sales" data-bs-parent="#accordionExample">
                                    {role === "ADMIN" && (

                                        <li>
                                            <NavLink to="/sales">
                                                Ventas
                                            </NavLink>
                                        </li>
                                    )}

                                    <li>
                                        <NavLink to="/sales/mylist">
                                            Mis Ventas
                                        </NavLink>
                                    </li>
                                </ul>
                            </li><li className="menu">
                                <a href="#stock" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle collapsed">
                                    <div className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                                        <span>Inventario</span>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                                    </div>
                                </a>
                                <ul className="collapse submenu list-unstyled" id="stock" data-bs-parent="#accordionExample">
                                    <li>
                                        <NavLink to="/products">
                                            Productos
                                        </NavLink>
                                    </li>
                                </ul>
                            </li></>
                    )}




                </ul>

            </nav>

        </div>
    );
}

export default Sidebar;