import * as Yup from 'yup';

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      'La contraseña debe incluir al menos una letra mayúscula, un número y un símbolo'
    )
    .required('La contraseña es obligatoria'),

  repeatpassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
    .required('Repetir contraseña es obligatorio'),
});

export default PasswordSchema;
