import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useData } from '../context/dataContext';

function Navbar() {
    const user = JSON.parse(sessionStorage.getItem("userData"));
    const id = user ? user.id_user : ""; 
    const {handleChange, getImgUser, imgUser } = useData(); 

    useEffect(() => {
        getImgUser(id); 
    }, []);

    return (
        <div className="header-container container-xxl">
            <header className="header navbar navbar-expand-sm expand-header" id="navbar">

                <a onClick={handleChange} className="sidebarCollapse">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                </a>

                <div className="search-animated toggle-search">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                    <form className="form-inline search-full form-inline search" role="search">
                        <div className="search-bar">
                            <input type="text" className="form-control search-form-control  ml-lg-auto" placeholder="Search..." />
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x search-close"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        </div>
                    </form>
                    <span className="badge badge-secondary">Ctrl + /</span>
                </div>

                <ul className="navbar-item flex-row ms-lg-auto ms-0">

                    <li className="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
                        <a className="nav-link dropdown-toggle user" id="userProfileDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="avatar-container">
                                <div className="avatar avatar-sm avatar-indicators avatar-online">
                                    <img alt="avatar" src={imgUser} className="rounded-circle" />
                                </div>
                            </div>
                        </a>

                        <div className="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                            <div className="user-profile-section">
                                <div className="media mx-auto">
                                    <div className="emoji me-2">
                                        👋
                                    </div>
                                    <div className="media-body">
                                        <h5>{user ? user.name : ""}</h5>
                                        <p>ROL: {user ? user.role : "null"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown-item">
                                <Link to={`/users/form/${id}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> <span>Perfil</span>
                                </Link>
                            </div>
                            <div className="dropdown-item">
                            <Link to="/change" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg> <span>Cambiar contraseña</span>
                            </Link>
                            </div>
                            <div className="dropdown-item">
                                <Link to="/signout" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg> <span>Cerrar sesion</span>
                                </Link>

                            </div>
                        </div>

                    </li>


                </ul>
            </header>
        </div>
    );
}

export default Navbar; 