

function Breadcrumb({options}) {
    return (
        <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><a>{options[0]}</a></li>
                <li className="breadcrumb-item"><a>{options[1]}</a></li>
                <li className="breadcrumb-item active" aria-current="page">{options[2]}</li>
            </ol>
        </nav>
    );
}

export default Breadcrumb;