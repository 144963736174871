import { Link, useNavigate, useParams } from 'react-router-dom';
import configureAxios from "../../helpers/axiosConfig";
import React, { useEffect, useState, useRef } from 'react';
import { baseUrl } from "../../helpers/baseUrl";
import DataTableBase from '../../helpers/DataTableBase';
import Breadcrumb from "../../components/Breadcrumb";


function SaleAdvancesPage() {
    const { id } = useParams();
    const axios = configureAxios();
    const [advances, setAdvances] = useState([]);
    const columns = [
        { name: 'Recibido por', selector: (row) => <div>{row.received_by}</div>, sortable: true, },
        { name: 'Fecha registrado', selector: (row) => <div>{row.date_created}</div>, sortable: true, },
        { name: 'Fecha recibido', selector: (row) => <div>{row.date_received}</div>, sortable: true, },
        { name: 'Cantidad', selector: (row) => <div>$ {row.quantity}</div>, sortable: true, },
        { name: 'Detalles', selector: (row) => row.details, sortable: true, cell: (row) => <div style={{ whiteSpace: 'normal' }}>{row.details}</div> },
    ]

    useEffect(() => { getAdvances() }, []);

    const getAdvances = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}sales/advances/${id}`);
            if (response.status === 200) {
                console.log(response.data.data);
                setAdvances(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div class="layout-px-spacing">
            <div class="middle-content container-xxl p-0">
                <div class="page-meta">
                    <Breadcrumb options={["Ventas", "Ventas", "Detalle de anticipos y pagos"]} />
                </div>
                <div className="row">
                    <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing">
                        <div class="widget-content widget-content-table br-8">
                            <div className="row">
                                <div className="col-12 my-4">
                                    <div className="d-flex justify-content-between">
                                        <div className="title">
                                            <h4>Detalles de anticipos de la venta No. {id} </h4>
                                        </div>
                                        <div className="mr-4">
                                            <Link to={`/sales/view/${id}`}>
                                                <a class="btn btn-success">Volver</a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="invoice-list_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                                <DataTableBase columns={columns} data={advances} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default SaleAdvancesPage;