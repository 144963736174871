import React, { useEffect, useRef, useState } from 'react';
import { useData } from '../../../context/dataContext';

function SaleFormPage05() {
    const { idBusiness, setIdBusiness } = useData();

    const handleClick = (value) => {
        setIdBusiness(value);
    };

    return (
        <div className="invoice-address-client-fields">
            <p>Selecciona a que negocio pertenece esta venta:
            </p>

            <div className="row d-flex justify-content-between align-items-center">
                <div className={`col mt-2 text-center ${idBusiness === 1 ? 'selected' : ''}`} onClick={() => handleClick(1)}>
                    <img src="/Cartagena.jpg" alt="" style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                </div>
                <div className={`col mt-2 text-center ${idBusiness === 2 ? 'selected' : ''}`} onClick={() => handleClick(2)}>
                    <img src="/Eko.jpg" alt="" style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                </div>
                <div className={`col mt-2 text-center ${idBusiness === 3 ? 'selected' : ''}`} onClick={() => handleClick(3)}>
                    <img src="/Nebula.jpg" alt="" style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                </div>
            </div>

        </div>
    );
}

export default SaleFormPage05;