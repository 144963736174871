import DataTable from 'react-data-table-component';


function DataTableBase({ columns, data }) {
    const handleRowClick = (row) => {
        console.log(row); 
    };

    const options = {
        onRowClick: (row) => handleRowClick(row),
    };

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            searchable
            selectableRows
            noHeader={false}
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 20, 50]}
            noDataComponent="No se encontraron registros"
            options={options}
            paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
                selectAllRowsItem: true,
                selectAllRowsItemText: 'Todos',
            }}
            className='react-table'
        />
    );
}

export default DataTableBase; 