import React, { useEffect, useState } from 'react';
import { baseUrl } from "../helpers/baseUrl";
import Chart from 'react-apexcharts';
import "../assets/css/dash_2.css";
import Swal from 'sweetalert2';
import { useData } from '../context/dataContext';
import configureAxios from "../helpers/axiosConfig";
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import DataTableBase from '../helpers/DataTableBase';

function HomePage() {
    const axios = configureAxios();
    const navigate = useNavigate();
    const user = JSON.parse(sessionStorage.getItem("userData"));
    const [salesBySeller, setSalesBySeller] = useState([]);
    const [salesByBusiness, setSalesByBusiness] = useState([]);
    const [salesByWeek, setSalesByWeek] = useState([]);
    const id = user ? user.id_user : "";
    const role = user ? user.role : "";
    const { getImgUser } = useData();
    const columns = [
        { name: 'Nombre', selector: (row) => <div className="td-content customer-name"><img src={row.url} style={{width: '35px', height: '35px', borderRadius: '6px', marginRight: '10px', padding: '2px', alignSelf: 'center'}} /><span>{row.first_name} {row.last_name}</span></div>, sortable: true, },
        { name: 'Total', selector: (row) => <div className="td-content pricing text-center">{row.total_orders}</div>, sortable: true, },
    ];

    useEffect(() => {
        if (role === "ADMIN") {
            AllSalesPerMonthBySellers();
            AllSalesPerMonthByBusiness();
            AllSalesPerLastWeek();
        }
        getImgUser(id);

    }, []);




    const AllSalesPerMonthByBusiness = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}analytics/salesbybusiness`);
            const totalsArray = response.data.data.map(item => parseInt(item.total));
            setSalesByBusiness(totalsArray);
        } catch (error) {
            console.log("No se encontraron registros de ventas para los negocios");
        }
    }

    const AllSalesPerMonthBySellers = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}analytics/salesbysellers`);
            setSalesBySeller(response.data.data);
        } catch (error) {
            console.log("No se encontraron registros de ventas para los usuarios");
        }
    }

    const AllSalesPerLastWeek = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}analytics/salesbyweek`);
            response.data.data.forEach(item => {
                const dayIndex = parseInt(item.day_of_week);
                totalsWeek[dayIndex] = parseInt(item.total);
            });
            setSalesByWeek(totalsWeek);
        } catch (error) {
            console.log("No se encontraron registros de venta para la ultima semana");
        }
    }


    const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const totalsWeek = Array(daysOfWeek.length).fill(0);
    const datosVentas = [120, 200, 150, 300, 180, 250, 210];
    const fechaActual = new Date();
    const nombresMeses = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    const nombreMesActual = nombresMeses[fechaActual.getMonth()];

    const chartData = {
        options: {
            labels: ['Cartagena', 'Eko', 'Nebula'],
            colors: ['#2447D4', '#EFEC0C', '#ff0000'],
            legend: {
                position: 'bottom',
            },
        },
        series: salesByBusiness, // Puedes ajustar estos valores según tus datos reales
    };

    const chartData2 = {
        options: {
            chart: {
                type: 'bar',
                height: 350,
            },
            xaxis: {
                categories: daysOfWeek,
            },
            title: {
                text: 'Total de Ventas en la ultima semana',
                align: 'center',
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    fontFamily: 'Arial, sans-serif',
                    color: '#333',
                },
            },
        },
        series: [
            {
                name: 'Ventas',
                data: salesByWeek,
                style: {
                    color: '#6600ff',
                }
            },
        ],
    };



    return (
        <div className="layout-px-spacing">
            <div className="middle-content container-xxl p-0">
                {role === "ADMIN" ? (
                    <div className="page-meta">
                        <Breadcrumb options={["Inicio", "Analiticas", "Estadisticas de ventas"]} />
                    </div>
                ) : (
                    <div className="page-meta">
                        <Breadcrumb options={["Inicio", "Bienvenida", "Grupo CYA"]} />
                    </div>
                )}

                <div className="row ">
                    {role === "ADMIN" ? (
                        <>
                            <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 layout-top-spacing layout-spacing">
                                <div className="widget widget-table-two">

                                    <div className="widget-heading">
                                        <h5 className="">Ventas por vendedor ({nombreMesActual})</h5>
                                    </div>

                                    <div className="widget-content">
                                        <DataTableBase columns={columns} data={salesBySeller} />
                                    </div>
                                </div>
                            </div><div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 layout-top-spacing layout-spacing">
                                <div className="widget widget-chart-two">
                                    <div className="widget-heading">
                                        <h5 className="">Ventas por negocio ({nombreMesActual})</h5>
                                    </div>
                                    <div className="widget-content">
                                        <Chart options={chartData.options} series={chartData.series} type="donut" width={380} />
                                    </div>
                                </div>
                            </div><div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                                <div className="widget widget-chart-two">
                                    <div className="widget-heading">
                                    </div>
                                    <div className="widget-content">
                                        <Chart options={chartData2.options} series={chartData2.series} type="bar" height={350} />
                                    </div>
                                </div>
                            </div></>
                    ) : role === "VENDEDOR" ? (
                        <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 layout-top-spacing layout-spacing">
                            <div className="widget widget-table-two text-center">
                                <img src="/CYA.jpg" alt="" className='m-0' width="300px" />
                                <h3>Bienvenido</h3>
                            </div>
                        </div>
                    ) : (
                        <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 layout-top-spacing layout-spacing">
                            <div className="widget widget-table-two text-center">
                                <img src="/CYA.jpg" alt="" className='m-0' width="100px" />
                                <h1>Bienvenido</h1>
                                <p>
                                    Tu rol actual es de INVITADO, solicita a tu jefe inmediato que modifique tu rol,
                                    una ves modificado, deberas CERRAR SESION e iniciar sesion nuevamente
                                </p>
                            </div>
                        </div>
                    )}


                </div>
            </div>
        </div>
    );
}

export default HomePage; 