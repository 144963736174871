import React from "react";
import { Outlet } from "react-router-dom";
import LoginPage from './LoginPage';

function AuthPage() {
    return (
        <Outlet/>
    );
}


export default AuthPage; 