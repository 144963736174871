import { useState } from "react";
export default function useToken() {

    const getToken = () => {
        const user = JSON.parse(sessionStorage.getItem("userData")) ?? null;
        return user; 
    }

    const [token, setToken] = useState(getToken());

    const saveToken = user => {
        sessionStorage.setItem('userData', JSON.stringify(user));
        setToken(user.token);
    }

    const destroyToken = () => {
        sessionStorage.removeItem('userData');
    }

    return {
        setToken: saveToken, 
        destroyToken,
        token
    }


}