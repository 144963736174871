import { useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useData } from '../../context/dataContext';

function LogOut({ deleteToken }) {
    
    const navigate = useNavigate();

    useEffect(() => {
        deleteToken(); 
        navigate('/login');
        window.location.href = "/login";

    }, []); 

    return null; 
    
}

export default LogOut; 