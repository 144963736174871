import React, { useEffect, useState } from 'react';

const Temporizador = () => {
  const fechaEvento = new Date("2023-12-04T12:00:00").getTime();
  const [tiempoRestante, setTiempoRestante] = useState(calcularTiempoRestante());

  useEffect(() => {
    const temporizador = setInterval(() => {
      setTiempoRestante(calcularTiempoRestante());
    }, 1000);

    return () => clearInterval(temporizador);
  }, []);

  function calcularTiempoRestante() {
    const fechaActual = new Date().getTime();
    const diferencia = fechaEvento - fechaActual;

    const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
    const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
    const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

    return { dias, horas, minutos, segundos };
  }

  return (
    <div>
      <p>{tiempoRestante.dias}d {tiempoRestante.horas}h {tiempoRestante.minutos}m {tiempoRestante.segundos}s</p>
    </div>
  );
};

export default Temporizador;
