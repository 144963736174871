import { Link, useNavigate } from 'react-router-dom';
import "./css/auth-cover.css";
import { useEffect, useState } from 'react';
import { baseUrl } from "../../helpers/baseUrl";
import Cookies from 'js-cookie';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Formik, Field, Form } from 'formik';
import LoginSchema from "../../validation/LoginSchema";
import SwalMsg from "../../helpers/SwalMsg";

function LoginPage({ setToken }) {
    const navigate = useNavigate();
    const initialValues = { username: '', password: '' }

    const hideAlertError = (status) => {
        document.getElementById('username_error').hidden = status;
        document.getElementById('password_error').hidden = status;
    }

    return (
        <div class="auth-container d-flex">

            <div class="container mx-auto align-self-center">

                <div class="row">

                    <div class="col-6 d-lg-flex d-none h-100 my-auto top-0 start-0 text-center justify-content-center flex-column">
                        <div class="auth-cover-bg-image"></div>
                        <div class="auth-overlay"></div>
                        <div class="auth-cover">
                            <div class="position-relative">
                                <img src="CYA.png" alt="auth-img" />
                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-12 d-flex flex-column align-self-center ms-lg-auto me-lg-0 mx-auto">
                        <div class="card">
                            <div class="card-body">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={LoginSchema}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values) => {

                                        try {
                                            const response = await axios.post(`${baseUrl.url}auth/login`, values, {
                                                timeout: 5000,
                                            });

                                            if (response.status === 200) {

                                                const userData = response.data.data;

                                                sessionStorage.setItem('userData', JSON.stringify(userData));

                                                setToken(userData);

                                                navigate('/home');
                                            }

                                        } catch (error) {
                                            console.log(error);
                                            if (error && error.response && error.response.status === 401) {
                                                hideAlertError(false);
                                            } else {
                                                SwalMsg.serverError();
                                            }
                                        }

                                    }}>

                                    {({ handleChange, handleSubmit, errors }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <div class="row">
                                                <div class="col-md-12 mb-3">

                                                    <h2>Iniciar sesion</h2>
                                                    <p>Introduce tu nombre de usuario y contraseña para continuar</p>

                                                </div>
                                                <div class="col-md-12">
                                                    <div class="mb-3">
                                                        <label class="form-label">Usuario</label>
                                                        <Field
                                                            type="text"
                                                            className="form-control add-billing-address-input"
                                                            id="username"
                                                            name="username"
                                                            onChange={handleChange}
                                                        />
                                                        {errors.username ? (
                                                            <div class="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                                {errors.username}
                                                            </div>
                                                        ) : null}
                                                        <div class="alert alert-danger p-2 my-1" id='username_error' role="alert" hidden>
                                                            Verifica tu nombre de usuario
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="mb-4">
                                                        <label class="form-label">Contraseña</label>
                                                        <Field
                                                            type="password"
                                                            className="form-control"
                                                            id="password"
                                                            name="password"
                                                            onChange={handleChange}
                                                        />
                                                        {errors.password ? (
                                                            <div className="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                                {errors.password}
                                                            </div>
                                                        ) : null}
                                                        <div class="alert alert-danger p-2 my-1" id='password_error' role="alert" hidden>
                                                            Verifica tu contraseña
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="mb-3">
                                                        <div class="form-check form-check-primary form-check-inline">
                                                            <Link to="/reset">
                                                                <a class="form-check-label" for="form-check-default">
                                                                    ¿Olvidaste tu contraseña?
                                                                </a>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="mb-4">
                                                        <button type='submit' class="btn btn-secondary w-100">ACCEDER</button>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="text-center">
                                                        <p class="mb-0">¿No tienes cuenta?
                                                            <Link to="/signup">
                                                                <a class="text-warning">
                                                                    Crear una
                                                                </a>
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}

                                </Formik>


                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default LoginPage; 