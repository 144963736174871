import { useEffect, useState, createContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../css/invoice-list.css";
import SaleFormPage01 from "./SaleFormPage01";
import SaleFormPage02 from "./SaleFormPage02";
import SaleFormPage03 from "./SaleFormPage03";
import SaleFormPage04 from "./SaleFormPage04";
import SaleFormPage05 from "./SaleFormPage05";
import Breadcrumb from "../../../components/Breadcrumb"; 


function SaleFormPage() {
    const [step, setStep] = useState(1);
    const navigate = useNavigate();

    const nextStep = () => {
        setStep(step + 1);
    }

    const prevStep = () => {
        setStep(step - 1);
    }


    useEffect(() => {
        if (step === 6) {
            navigate('/sales/detail');
        }
        if (step <= 0) {
            navigate('/sales/mylist');
        }

    }, [step]);

    const renderStep = () => {
        switch (step) {
            case 1:
                return <SaleFormPage01 />;
            case 2:
                return <SaleFormPage02 />;
            case 3:
                return <SaleFormPage03 />;
            case 4:
                return <SaleFormPage04 />;
            case 5:
                return <SaleFormPage05 />;
            default:
                return null;
        }
    };


    return (


        <div className="layout-px-spacing">
            <div className="middle-content container-xxl p-0">
                <div className="page-meta">
                    <Breadcrumb options={["Ventas", "Ventas", "Nueva venta"]} />
                </div>
                <div className="row" id="cancel-row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing">
                        <div className="widget-content widget-content-area br-8">
                            {renderStep()}
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between" >
                    <button className="btn btn-danger text-start" onClick={prevStep}>{step === 1 ? "Cancelar" : "Anterior"}</button>
                    <button className="btn btn-primary text-end" onClick={nextStep}>{step === 5 ? "Ver detalles" : "Siguiente"}</button>
                </div>


            </div>
        </div>




    );
}

export default SaleFormPage;