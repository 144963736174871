import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./css/contacts.css";
import $ from 'jquery';
import 'datatables.net';
import Swal from 'sweetalert2';
import { baseUrl } from "../../helpers/baseUrl";
import configureAxios from "../../helpers/axiosConfig";
import DataTableBase from '../../helpers/DataTableBase';
import Breadcrumb from '../../components/Breadcrumb';
import SwalMsg from '../../helpers/SwalMsg';

function CustomerListPage() {
    const axios = configureAxios();
    const user = JSON.parse(sessionStorage.getItem("userData")) ?? null;
    const id_user = user.id_user;
    const rol = user.role;
    const navigate = useNavigate();
    const [customerData, setCustomerData] = useState([]);
    const columns = [
        {
            name: 'Nombre',
            selector: (row) => row.first_name, // Ajusta esto al nombre real de la propiedad en tus datos
            sortable: true,
            cell: row => <div>{row.first_name} {row.last_name} </div>, // Opcional: personaliza cómo se renderiza el contenido de la celda
        },
        {
            name: 'Correo',
            selector: (row) => row.email, // Ajusta esto al nombre real de la propiedad en tus datos
            sortable: true,
            cell: row => <div>{row.email}</div>, // Opcional: personaliza cómo se renderiza el contenido de la celda
        },
        {
            name: 'Telefono',
            selector: (row) => row.phone_number, // Ajusta esto al nombre real de la propiedad en tus datos
            sortable: true,
            cell: row => <div>{row.phone_number}</div>, // Opcional: personaliza cómo se renderiza el contenido de la celda
        },
        {
            name: 'Creado por',
            selector: (row) => row.user_firstname, // Ajusta esto al nombre real de la propiedad en tus datos
            sortable: true,
            cell: row => <div>{row.user_firstname} {row.user_lastname}</div>, // Opcional: personaliza cómo se renderiza el contenido de la celda
        },
        {
            name: 'Acciones',
            selector: (row) => row.acciones,
            sortable: false,
            cell: row => (
                <Link to={`/customers/form/${row.id_customer}`} className='badge badge-light-primary text-start me-2 action-edit'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                </Link>
            ),
        },
    ];

    useEffect(() => {
        if (rol === "ADMIN") {
            fetchData();
        } else {
            fetchDataByIdUser();
        }
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}customers`);
            if (response && response.status === 200) {
                setCustomerData(response.data.data);
            }
        } catch (error) {
            console.log("Ocurrio un error al obtener los datos de los clientes");
            SwalMsg.serverError();
        }
    };

    const fetchDataByIdUser = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}customers/user/${id_user}`);
            if (response && response.status === 200) {
                setCustomerData(response.data.data);
            }
        } catch (error) {
            console.log("Ocurrio un error al obtener los datos de los clientes");
            SwalMsg.serverError();
        }
    };

    return (
        <div className="layout-px-spacing">

            <div className="middle-content container-xxl p-0">
                <div className="page-meta">
                    <Breadcrumb options={["Usuarios", "Clientes", "Listado de clientes"]} />
                </div>
                <div className="row" id="cancel-row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing">
                        <div className="widget-content widget-content-table br-8">
                            <div id="invoice-list_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                                <div className="inv-list-top-section">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center">

                                            <div className="dt-action-buttons align-self-center">
                                                <div className="dt-buttons">
                                                    <Link to="/customers/form">
                                                        <button className="dt-button btn btn-primary _effect--ripple waves-effect waves-light" tabIndex="0" aria-controls="invoice-list"><span>Nuevo cliente</span></button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <DataTableBase columns={columns} data={customerData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerListPage;