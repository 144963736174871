import { Link, useNavigate, useParams } from 'react-router-dom';
import configureAxios from "../../helpers/axiosConfig";
import ImageSlider from "../../components/ImageSlider";
import "./css/ecommerce-details.css";
import { useEffect, useState } from 'react';
import { baseUrl } from "../../helpers/baseUrl";
import "./css/product_view.css";
import Breadcrumb from '../../components/Breadcrumb';

function ProductViewPage() {
    const navigate = useNavigate();
    const axios = configureAxios();
    const { id } = useParams();
    const [product, setProduct] = useState([]);
    const [img, setImg] = useState([]);

    useEffect(() => {
        getProduct();
        getImages();
    }, []);

    const getProduct = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}products/${id}`);
            setProduct(response.data.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getImages = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}products/img/${id}`);
            const urls = response.data.data.map(item => item.url);
            setImg(urls);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="layout-px-spacing">
            <div className="middle-content container-xxl p-0">
                <div className="page-meta">
                    <Breadcrumb options={["Inventario", "Productos", "Detalles del producto"]} />
                </div>
                <div className="row layout-top-spacing">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                        <div className="widget-content widget-content-area br-8" style={{ minHeight: '300px' }}>
                            <div className="row justify-content-center">
                                <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-7 col-sm-9 col-12 pe-3">
                                    <div id="main-slider" className=''>
                                        <ImageSlider images={img} />
                                    </div>
                                </div>

                                <div className="col-xxl-4 col-xl-5 col-lg-12 col-md-12 col-12 align-self-start">
                                    <div className="product-details-content">

                                        <span className="badge badge-light-danger mb-3">Detalles del producto</span>

                                        <h3 className="product-title mb-0">{product.name}</h3>

                                        <div className="row">
                                            <div className="col-md-9 col-sm-9 col-9">
                                                <div className="pricing">
                                                    <span className="discounted-price">$ {product.price}</span>
                                                    {/* <span className="regular-price">$30</span> */}
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-sm-3 col-3 text-end">
                                                <div className="product-share">
                                                    <button className="btn btn-light-success btn-icon btn-rounded _effect--ripple waves-effect waves-light">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-share-2"><circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle><circle cx="18" cy="19" r="3"></circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line></svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="action-button text-justify mt-4">
                                            {product.description}
                                        </div>
                                    </div>

                                    <div className="action-button text-center mt-4">
                                        <div className="row buttons">
                                            <div className="col-xxl-8 col-xl-6 col-sm-6">
                                                <Link to={`/products/list`}>
                                                    <button className="btn btn-sm btn-danger">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                                                        &nbsp; Volver al listado
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-sm-6">
                                                <Link to={`/products/form/${id}`}>
                                                    <button className="btn btn-sm btn-success">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                                                        &nbsp; Editar producto
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductViewPage; 