import Swal from 'sweetalert2';
import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'datatables.net';
import axios from 'axios';
import { baseUrl } from "../../helpers/baseUrl";
import DataTableBase from '../../helpers/DataTableBase';

import "./css/datatables.css";
import Breadcrumb from '../../components/Breadcrumb';

function UserListPage() {
    const [userdata, setUserData] = useState([]);
    const [userUpdate, setUserUpdate] = useState([]);
    const [roles, setRoles] = useState([]);
    const columns = [
        { name: 'Usuario', selector: (row) => row.username, sortable: true },
        { name: 'Nombre', selector: (row) => row.first_name, sortable: true, cell: row => <div>{row.first_name} {row.last_name}</div> },
        { name: 'Correo', selector: (row) => row.email, sortable: true },
        { name: 'Rol', selector: (row) => row.rol_name, sortable: true },
        { name: 'Ultimo acceso', selector: (row) => row.last_login, sortable: true, width: '180px' },
        {
            name: 'Acciones',
            selector: row => row.acciones,
            cell: row => (
                <a data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handleUpdate(row)} className="badge badge-light-primary text-start me-2 action-edit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                </a>
            ),
        },
    ];

    useEffect(() => {
        fetchData();
        getRoles();
    }, []);

    /* useEffect(() => {
        if (userdata.length > 0) {
            initializedDatatable();

        }
    }, [userdata]); */


    const fetchData = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}users`);
            setUserData(response.data.data);
        } catch (error) {
            console.error('Error al obtener los datos de los usuarios');
        }
    };

    const getRoles = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}roles`);
            setRoles(response.data.data);
        } catch (error) {
            console.error('Error al obtener los roles de los usuarios');
        }
    };


    const handleUpdate = (user) => {
        setUserUpdate(user);
    }
    const [selectedRolId, setSelectedRolId] = useState(null); // Inicializa el estado con null o el valor predeterminado que desees

    const updateRolId = (event) => {
        const selectedId = event.target.value;
        setSelectedRolId(selectedId);
    };

    const handleUpdateRol = async () => {
        console.log(selectedRolId);

        try {
            if (selectedRolId === 0 || selectedRolId === null) {
                throw new Error("Por favor, seleccione un rol válido.");
            }
            const rol = {
                id_rol: selectedRolId
            }

            const response = await axios.put(`${baseUrl.url}users/rol/${userUpdate.id_user}`, rol);

            if (response.status === 200) {
                Swal.fire({
                    title: '¡Registro actualizado!',
                    text: 'El rol del usuario se ha actualizado exitosamente',
                    icon: 'success',
                    padding: '2em',
                    confirmButtonText: 'Aceptar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                }
                );
            }

        } catch (error) {
            console.log(error);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'warning',
                padding: '2em',
            });

        }
    }

    return (
        <>
            <div className="layout-px-spacing">
                <div className="middle-content container-xxl p-0">
                    <div className="page-meta">
                        <Breadcrumb options={["Usuarios", "Usuarios", "Listado de usuarios"]} />
                    </div>
                    <div className="row" id="cancel-row">
                        <div className="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing">
                            <div className="widget-content widget-content-table br-8">
                                <div id="invoice-list_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                                    <div className="inv-list-top-section">

                                    </div>
                                    <DataTableBase columns={columns} data={userdata} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <svg> ... </svg>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group mb-4">
                                <label htmlFor="formGroupExampleInput">Usuario</label>
                                <input type="text" name='username' className="form-control" value={userUpdate.username} disabled />
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="formGroupExampleInput">Rol</label>
                                <select className='form-control' onChange={updateRolId}>
                                    <option value="0">Seleccione un rol...</option>
                                    {roles.map((rol, i) => (
                                        <option key={i} value={rol.id_rol}> {rol.name} </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn btn-light-danger" data-bs-dismiss="modal"><i className="flaticon-cancel-12"></i> Cancelar</button>
                            <button type="button" className="btn btn-primary" onClick={handleUpdateRol}> Actualizar </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserListPage; 