import React, { useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function ProtectedRoute({ component: Component, allowedRoles }) {
  const user = JSON.parse(sessionStorage.getItem("userData"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else 

    if (!allowedRoles.includes(user.role)) { 
      navigate('/error');
      
    }
  }, [user, allowedRoles, navigate]);

  return <Component />;
}

export default ProtectedRoute;