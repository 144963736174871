import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import { StyleSheetManager } from 'styled-components';
import Sidebar from './components/Sidebar';
import HomePage from './pages/HomePage';
import Maintenance from './pages/maintenance/Maintenance';
import AuthPage from './pages/auth/AuthPage';
import ProtectedRoute from './pages/auth/ProtectedRoute';
import LoginPage from './pages/auth/LoginPage';
import SignupPage from './pages/auth/SignupPage';
import ResetPage from './pages/auth/ResetPage';
import LogOut from './pages/auth/LogOut';
import UserHomePage from './pages/users/UserHomePage';
import UserListPage from './pages/users/UserListPage';
import UserFormPage from './pages/users/UserFormPage';
import UserChangePage from './pages/users/UserChangePage';
import ProductHomePage from './pages/products/ProductHomePage';
import ProductListPage from './pages/products/ProductListPage';
import ProductFormPage from './pages/products/ProductFormPage';
import ProductViewPage from './pages/products/ProductViewPage';
import SaleHomePage from './pages/sales/SaleHomePage';
import SaleListPage from './pages/sales/SaleListPage';
import Test from './pages/products/Test';
import SaleList2Page from './pages/sales/SaleList2Page';
import SaleFormPage from './pages/sales/Form/SaleFormPage';
import SaleViewPage from './pages/sales/Form/SaleViewPage';
import SaleDetailPage from './pages/sales/Form/SaleDetailPage';
import CustomerHomePage from './pages/customers/CustomerHomePage';
import CustomerListPage from './pages/customers/CustomerListPage';
import CustomerFormPage from './pages/customers/CustomerFormPage';
import Notallowed from './pages/errors/Notallowed';
import "../src/assets/css/plugins.css";
import { DataProvider } from './context/dataContext';
import useToken from "./context/useToken";
import ChangePage from './pages/auth/ChangePage';
import SaleAdvancesPage from './pages/sales/SaleAdvancesPage';
import AccountingPage from './pages/AccountingPage';

const user = JSON.parse(sessionStorage.getItem("userData")) ?? null;

function App() {
  const { token, setToken, destroyToken } = useToken();

  if (!token) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<AuthPage />}>
            <Route index element={<Navigate to="login" />} />
            <Route path='*' element={<Navigate to="login" />} />
            <Route path='login' element={<LoginPage setToken={setToken} />} />
            <Route path='signup' element={<SignupPage />} />
            <Route path='reset' element={<ResetPage />} />
            <Route path='reset' element={<ChangePage />} />
            <Route path='signout' element={<LogOut />} />
            <Route path='maintenance' element={<Maintenance />} />
            <Route path='reset-password/:token?' element={<ChangePage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } else {
    return (
      <StyleSheetManager shouldForwardProp={(prop) => prop !== 'sortActive'}>
        <DataProvider>
          <BrowserRouter>
            <Navbar />
            <div className='main-container' id='container'>
              <div className="overlay"></div>
              <div className="search-overlay"></div>
              <Sidebar />
              <div className='main-content' id='content'>
                <Routes>
                  <Route path='/home' element={<HomePage />} />
                  <Route path='/accounting' element={<AccountingPage />} />
                  <Route index element={<Navigate to="home" />} />
                  <Route path='*' element={<Navigate to="home" />} />
                  <Route path='signout' element={<LogOut deleteToken={destroyToken} />} />
                  <Route path="/products/*" element={<ProtectedRoute component={ProductHomePage} allowedRoles={['ADMIN', 'VENDEDOR']} />} >
                    <Route path="" element={<Navigate to="list" />} />
                    <Route path="list" element={< ProductListPage />} />
                    <Route path="view/:id?" element={< ProductViewPage />} />
                    <Route path="form/:id?" element={< ProductFormPage />} />
                  </Route>

                  <Route path="/sales/*" element={<ProtectedRoute component={SaleHomePage} allowedRoles={['ADMIN', 'VENDEDOR']} />} >
                    <Route path="" element={<Navigate to="list" />} />
                    <Route path="list" element={<ProtectedRoute component={SaleListPage} allowedRoles={['ADMIN']} />} />
                    <Route path="mylist" element={<ProtectedRoute component={SaleList2Page} allowedRoles={['ADMIN', 'VENDEDOR']} />} />
                    <Route path="new" element={< SaleFormPage />} />
                    <Route path="detail" element={< SaleDetailPage />} />
                    <Route path="view/:id?" element={< SaleViewPage />} />
                    <Route path="advances/:id?" element={< SaleAdvancesPage />} />
                  </Route>

                  <Route path="/customers/*" element={<ProtectedRoute component={CustomerHomePage} allowedRoles={['ADMIN', 'VENDEDOR']} />} >
                    <Route path="" element={<Navigate to="list" />} />
                    <Route path="list" element={< CustomerListPage />} />
                    <Route path="form/:id?" element={< CustomerFormPage />} />
                  </Route>

                  <Route path="/users/*" element={<ProtectedRoute component={UserHomePage} allowedRoles={['ADMIN', 'INVITADO', 'VENDEDOR']} />} >
                    <Route path="" element={<Navigate to="list" />} />
                    <Route path="list" element={<ProtectedRoute component={UserListPage} allowedRoles={['ADMIN']} />} />
                    <Route path="form/:id?" element={<ProtectedRoute component={UserFormPage} allowedRoles={['ADMIN', 'VENDEDOR']} />} />
                  </Route>
                </Routes>
              </div>
            </div>
          </BrowserRouter>
        </DataProvider>
      </StyleSheetManager>


    );
  }


}

export default App;


function Main() {
  return (
    <>
      <Navbar />
      <div className='main-container' id='container'>
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <Sidebar />
        <div className='main-content' id='content'>
          <Routes>
            <Route path="/home" element={<ProtectedRoute component={HomePage} allowedRoles={['ADMIN', 'VENDEDOR', 'INVITADO']} />} />
            <Route path="*" element={<Navigate to="home" />} />
            <Route path="error" element={< Notallowed />} />
            <Route path="/products/*" element={<ProtectedRoute component={ProductHomePage} allowedRoles={['ADMIN', 'VENDEDOR']} />} >
              <Route path="" element={<Navigate to="list" />} />
              <Route path="list" element={< ProductListPage />} />
              <Route path="test" element={< Test />} />
              <Route path="form/:id?" element={< ProductFormPage />} />
            </Route>
            <Route path="/sales/*" element={<ProtectedRoute component={SaleHomePage} allowedRoles={['ADMIN', 'VENDEDOR']} />} >
              <Route path="" element={<Navigate to="list" />} />
              <Route path="list" element={<ProtectedRoute component={SaleListPage} allowedRoles={['ADMIN']} />} />
              <Route path="mylist" element={<ProtectedRoute component={SaleList2Page} allowedRoles={['ADMIN', 'VENDEDOR']} />} />
              <Route path="new" element={< SaleFormPage />} />
              <Route path="detail" element={< SaleDetailPage />} />
              <Route path="view/:id?" element={< SaleViewPage />} />
            </Route>
            <Route path="/customers/*" element={<ProtectedRoute component={CustomerHomePage} allowedRoles={['ADMIN', 'VENDEDOR']} />} >
              <Route path="" element={<Navigate to="list" />} />
              <Route path="list" element={< CustomerListPage />} />
              <Route path="form/:id?" element={< CustomerFormPage />} />
            </Route>
            <Route path="/users/*" element={<ProtectedRoute component={UserHomePage} allowedRoles={['ADMIN', 'INVITADO', 'VENDEDOR']} />} >
              <Route path="" element={<Navigate to="list" />} />
              <Route path="list" element={<ProtectedRoute component={UserListPage} allowedRoles={['ADMIN']} />} />
              <Route path='change-password' element={<ProtectedRoute component={UserChangePage} allowedRoles={['ADMIN']} />} />
              <Route path="form/:id?" element={<ProtectedRoute component={UserFormPage} allowedRoles={['ADMIN', 'VENDEDOR']} />} />
            </Route>
          </Routes>
        </div>
      </div>
    </>
  );
}