import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useData } from '../../../context/dataContext';
import React, { useEffect, useState, useRef } from 'react';
import { baseUrl } from "../../../helpers/baseUrl";
import "../css/invoice-list.css";
import 'font-awesome/css/font-awesome.min.css';
import configureAxios from "../../../helpers/axiosConfig";
import { Formik, Field, Form } from 'formik';
import AdvancesSchema from "../../../validation/AdvancesSchema";
import CardStatus from '../../../components/CardStatus';
import SwalMsg from "../../../helpers/SwalMsg";

function SaleViewPage() {
    const axios = configureAxios();
    const { getImgUser } = useData();
    const [order, setOrder] = useState([]);
    const flatpickrRef = useRef(null);
    const [advances, setAdvances] = useState([]);
    const [productDetail, setPoductDetail] = useState([]);
    const [detailPayment, setDetailPayment] = useState([]);
    const [details, setDetails] = useState([]);
    const [payments, setPayments] = useState([]);
    const [statusSelected, setStatusSelected] = useState("");
    const user = JSON.parse(sessionStorage.getItem("userData"));
    const id_user = user ? user.id_user : "";
    const [updateStatus, setUpdateStatus] = useState({
        id_status: 0,
        detail: "",
        id_user: id_user
    });
    const { id } = useParams();
    let cont = 0;
    const initialValues = {
        date_received: "",
        quantity: 0,
        received_by: "",
        details: "",
        created_by: id_user
    }

    const handleRowClick = (item) => {
        setPoductDetail(item);
    };

    const savePdf = () => {
        window.print()
    };


    const handleUpdateStatusSelected = (newStatusId) => {
        if (newStatusId == 2) {
            setStatusSelected("EN PRODUCCION");
        }
        if (newStatusId == 3) {
            setStatusSelected("FINALIZADA");
        }
        if (newStatusId == 4) {
            setStatusSelected("CANCELADA");
        }
        setUpdateStatus(prevStatus => ({
            ...prevStatus,  // Propagamos el estado anterior
            id_status: newStatusId,  // Actualizamos solo el id_status
        }));
    };

    const handleDetailChange = (e) => {
        const newDetail = e.target.value;
        setUpdateStatus(prevStatus => ({
            ...prevStatus,
            detail: newDetail,
        }));
    };

    useEffect(() => {
        getOrder();
        getDetailsPayments();
        getDetails();
        getPaymentsMethods();
        getImgUser(id_user);
    }, []);

    const getPaymentsMethods = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}payments`);

            setPayments(response.data.data);

        } catch (error) {
            console.error('Error al obtener los datos de las ventas: ', error);
        }
    }

    const getOrder = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}sales/sale/${id}`);
            if (response.status === 200) {
                setOrder(response.data.data)
            }
        } catch (error) {
            console.error('Error al obtener los datos de las ventas: ', error);
        }
    }

    const getAdvances = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}sales/advances/${id}`);
            if (response.status === 200) {
                setAdvances(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getDetailsPayments = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}sales/advance/${id}`);
            if (response.status === 200) {
                setDetailPayment(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getDetails = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}sales/details/${id}`);
            if (response.status === 200) {
                setDetails(response.data.data);
            }
        } catch (error) {
            console.error('Error al obtener los datos del detalle de las ventas: ', error);
        }
    }

    const handleUpdateStatus = async () => {
        console.log(updateStatus);
        if (!updateStatus.id_status || !updateStatus.detail || !updateStatus.id_user) {
            alert("Por favor, complete todos los campos");
            return;
        }
        const data = updateStatus;

        try {
            const response = await axios.post(`${baseUrl.url}sales/status/${id}`, data, {
                timeout: 5000,
            });

            if (response.status === 200) {
                Swal.fire({
                    title: '¡Estado actualizado!',
                    text: 'El estado de la orden se ha actualizado exitosamente',
                    icon: 'success',
                    padding: '2em',
                    confirmButtonText: 'Aceptar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                }
                );
            }
        } catch (error) {
            console.log(error);
            SwalMsg.serverError();
        }
    }

    return (
        <div class="layout-px-spacing">
            <div class="middle-content container-xxl p-0">
                <div class="row invoice layout-top-spacing layout-spacing">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="doc-container">
                            <div class="row">
                                <div class="col-xl-9">
                                    <div id='content-print' class="invoice-container">
                                        <div class="invoice-inbox">
                                            <div id="ct" class="">
                                                <div class="invoice-00001">
                                                    <div class="content-section">

                                                        <div class="inv--head-section inv--detail-section inv--customer-detail-section">

                                                            <div class="row">

                                                                <div class="col-sm-6 col-12 mr-auto">
                                                                    <div class="d-flex">
                                                                        <img class="company-logo" src="/CYA.jpg" alt="company" style={{ width: '70px', height: '70px' }} />
                                                                        <h3 class="in-heading align-self-center"></h3>
                                                                    </div>
                                                                    <p class="inv-street-addr mt-3">Grupo CYA S.A. de C.V.</p>
                                                                    <p class="inv-email-address">contact@grupocyasv.com</p>
                                                                    <p class="inv-email-address">(503) 7382-7383</p>
                                                                </div>

                                                                <div class="col-sm-6 text-sm-end">
                                                                    <p class="inv-list-number mt-sm-3 pb-sm-2 mt-4"><span class="inv-title">Venta No. {order.id_order}</span></p>
                                                                    <p class="inv-created-date mt-sm-5 mt-3"><span class="inv-title">Fecha de registro : {order.order_date}</span> <span class="inv-date"></span></p>
                                                                    <p class="inv-due-date"><span class="inv-title">Fecha de entrega: {order.delivery_date} </span> <span class="inv-date"></span></p>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="inv--detail-section inv--customer-detail-section">

                                                            <div class="row">

                                                                <div class="col-xl-8 col-lg-7 col-md-6 col-sm-4 align-self-center">
                                                                    <p class="inv-to">Datos del cliente</p>
                                                                </div>

                                                                <div class="col-xl-4 col-lg-5 col-md-6 col-sm-8 align-self-center order-sm-0 order-1 text-sm-end mt-sm-0 mt-5">
                                                                    <h6 class=" inv-to">Direccion de entrega</h6>
                                                                </div>

                                                                <div class="col-xl-8 col-lg-7 col-md-6 col-sm-4">
                                                                    <p class="inv-customer-name">
                                                                        {order.first_name}  {order.last_name}
                                                                    </p>
                                                                    {order.email ? order.email : "No tiene correo registrado"} <br />
                                                                    {order.phone_number}
                                                                </div>

                                                                <div class="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 order-sm-0 order-1 text-sm-end">
                                                                    {order.home_number ? order.home_number : "Sin No. de Casa"}  <br />
                                                                    {order.address} <br />
                                                                    {order.city} {order.department}
                                                                </div>

                                                                <div class="col-xl-8 col-lg-7 col-md-6 col-sm-4 align-self-center mt-4">
                                                                    <p class="inv-to">Datos del vendedor:</p> <p>{order.seller_first_name} {order.seller_last_name} <br /> {order.seller_email} <br /> {order.seller_phone}</p>
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class="inv--product-table-section">
                                                            <div class="table-responsive">
                                                                <table class="table">
                                                                    <thead class="">
                                                                        <tr>
                                                                            <th scope='col'>Detalles</th>
                                                                            <th scope="col">No</th>
                                                                            <th scope="col">Producto</th>
                                                                            <th class="text-end" scope="col">Cantidad</th>
                                                                            <th class="text-end" scope="col">Precio</th>
                                                                            <th class="text-end" scope="col">Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {details.map((item, rowIndex) => (
                                                                            <React.Fragment key={item.id}>
                                                                                <tr onClick={() => handleRowClick(item)} data-bs-toggle="modal" data-bs-target="#modalDetalle">
                                                                                    <td>
                                                                                        <i className="fa fa-eye fa-ms"></i>
                                                                                    </td>
                                                                                    <td>{cont += 1}</td>
                                                                                    <td>{item.name}</td>
                                                                                    <td class="text-end">{item.quantity}</td>
                                                                                    <td class="text-end">${item.price}</td>
                                                                                    <td class="text-end">${item.total}</td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div class="inv--total-amounts">

                                                            <div class="row mt-4">
                                                                <div class="col-sm-5 col-12 order-sm-0 order-1">
                                                                    <div className="row">
                                                                        <div class="col-sm-6 col-7">
                                                                            <p class="">Abonado:</p>
                                                                        </div>
                                                                        <div class="col-sm-6 col-5">
                                                                            <p class="">$ {detailPayment.advances}</p>
                                                                        </div>
                                                                        <div class="col-sm-6 col-7">
                                                                            <p class=""> <strong>Pendiente:</strong> </p>
                                                                        </div>
                                                                        <div class="col-sm-6 col-5">
                                                                            <p class=""> <strong>$ {detailPayment.pending}</strong> </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-7 col-12 order-sm-1 order-0">
                                                                    <div class="text-sm-end">
                                                                        <div class="row">
                                                                            <div class="col-sm-8 col-7">
                                                                                <p class="">Sub Total :</p>
                                                                            </div>
                                                                            <div class="col-sm-4 col-5">
                                                                                <p class="">$ {order.total}</p>
                                                                            </div>
                                                                            <div class="col-sm-8 col-7 grand-total-title mt-3">
                                                                                <h4 class="" style={{ color: '#000' }}>Total : </h4>
                                                                            </div>
                                                                            <div class="col-sm-4 col-5 grand-total-amount mt-3">
                                                                                <h4 class="" style={{ color: '#000' }}>$ {order.total} </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3">

                                    <div class="invoice-actions-btn">

                                        <div class="invoice-action-btn">

                                            <div class="row">
                                                <div class="col-xl-12 col-md-3 col-sm-6">
                                                    <a class="btn btn-primary btn-send _effect--ripple waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#exampleModal">Registrar Pago</a>
                                                </div>

                                                {user.role === "ADMIN" ? (
                                                    <div class="col-xl-12 col-md-3 col-sm-6">
                                                        <a class="btn btn-secondary btn-download _effect--ripple waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#modalEstado">Cambiar estado</a>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}

                                                <div class="col-xl-12 col-md-3 col-sm-6">
                                                    <a class="btn btn-success btn-send _effect--ripple waves-effect waves-light" onClick={savePdf}>Guardar PDF</a>
                                                </div>
                                                <div class="col-xl-12 col-md-3 col-sm-6">
                                                    <Link to={`/sales/advances/${id}`}>
                                                        <a class="btn btn-info btn-send _effect--ripple waves-effect waves-light">Ver pagos</a>
                                                    </Link>
                                                </div>
                                                <div class="col-xl-12 col-md-3 col-sm-6">
                                                    <Link to="/sales/mylist">
                                                        <a class="btn btn-danger btn-edit _effect--ripple waves-effect waves-light">Volver</a>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="invoice-actions-btn mt-4">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                {order.id_status === 1 && (
                                                    <div>
                                                        <h5>REGISTRADA</h5>
                                                        {order.detail_updated}
                                                    </div>
                                                )}
                                                {order.id_status === 2 && (
                                                    <div>
                                                        <h5>EN PRODUCCION</h5>
                                                        {order.detail_updated}
                                                    </div>
                                                )}
                                                {order.id_status === 3 && (
                                                    <div>
                                                        <h5>FINALIZADA</h5>
                                                        {order.detail_updated}
                                                    </div>
                                                )}
                                                {order.id_status === 4 && (
                                                    <div>
                                                        <h5>CANCELADA</h5>
                                                        {order.detail_updated}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">

                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Nuevo registro de pago</h5>

                        </div>
                        <div class="modal-body">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={AdvancesSchema}
                                validateOnChange={true}
                                validateOnBlur={false}
                                onSubmit={async (values) => {
                                    console.log(values);

                                    try {
                                        const response = await axios.post(`${baseUrl.url}sales/advances/${id}`, values, {
                                            timeout: 5000,
                                        });

                                        if (response.status === 201) {
                                            Swal.fire({
                                                title: '¡Pago guardado!',
                                                text: 'El pago se ha creado exitosamente',
                                                icon: 'success',
                                                padding: '2em',
                                                confirmButtonText: 'Aceptar',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    window.location.reload();
                                                }
                                            }
                                            );
                                        }

                                    } catch (error) {
                                        if (error.response && error.response.status === 422) {
                                            Swal.fire({
                                                title: '¡Pago no registrado!',
                                                text: "El monto ingresado excede el total de la orden y/o los pagos ya registrados",
                                                icon: 'warning',
                                                padding: '2em',
                                                confirmButtonText: 'Aceptar',
                                            })
                                        } else {
                                            console.log(error.message);
                                        }

                                    }
                                }}>

                                {({ handleChange, handleSubmit, errors }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <div className='row'>
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <label for="fullName">Fecha recibido</label>
                                                    <Field
                                                        type="date"
                                                        className="form-control form-control-sm "
                                                        id="date_received"
                                                        name="date_received"
                                                        onChange={handleChange}
                                                    />
                                                    {errors.date_received ? (
                                                        <div class="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                            {errors.date_received}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div class="form-group">
                                                    <label for="fullName">Cantidad</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="quantity"
                                                        name="quantity"
                                                        onChange={handleChange}
                                                    />
                                                    {errors.quantity ? (
                                                        <div class="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                            {errors.quantity}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div class="form-group">
                                                    <label for="fullName">Recibido por</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="received_by"
                                                        name="received_by"
                                                        onChange={handleChange}
                                                    />
                                                    {errors.received_by ? (
                                                        <div class="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                            {errors.received_by}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div class="form-group">
                                                    <label for="client-phone">Metodo de pago</label>
                                                    <Field
                                                        as="select"
                                                        className="form-control form-control-sm"
                                                        name="id_payment_method"
                                                        onChange={handleChange}
                                                    >
                                                        <option value="" label="Selecciona un método de pago" />
                                                        {payments.map((payment) => (
                                                            <option key={payment.id_payment} value={payment.id_payment}>
                                                                {payment.name}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                </div>
                                                <div class="form-group">
                                                    <label for="fullName">Detalles adicionales</label>
                                                    <Field
                                                        as="textarea"
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="details"
                                                        name="details"
                                                        onChange={handleChange}
                                                    />
                                                    {errors.details ? (
                                                        <div class="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                            {errors.details}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div d-flex justify-content-between mt-4">
                                            <button type='button' class="btn btn btn-light-danger" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> Cancelar</button>
                                            <button type="submit" class="btn btn-primary"> Guardar </button>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="modalEstado" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Cambiar el estado del pedido</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <svg> ... </svg>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="row mt-3 d-flex justify-content-center">
                                <div className="col-12 col-md-4">
                                    <CardStatus url="https://grupocyasv.nyc3.cdn.digitaloceanspaces.com/estados/produccion.png" estado="En produccion" funcion={() => handleUpdateStatusSelected(2)} />
                                </div>
                                <div className="col-12 col-md-4">
                                    <CardStatus url="https://grupocyasv.nyc3.cdn.digitaloceanspaces.com/estados/finalizado.png" estado="Finalizada" funcion={() => handleUpdateStatusSelected(3)} />
                                </div>
                                <div className="col-12 col-md-4">
                                    <CardStatus url="https://grupocyasv.nyc3.cdn.digitaloceanspaces.com/estados/cancelado.png" estado="Cancelada" funcion={() => handleUpdateStatusSelected(4)} />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12">
                                    Estado seleccionado: {statusSelected}
                                </div>
                            </div>

                            <div className="row  mt-4">
                                <p>Ingrese el motivo: </p>
                                <div className="col">
                                    <textarea name="" id="" rows="5" className='form-control form-control-sm' onChange={handleDetailChange}></textarea>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button class="btn btn btn-outline-danger" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> Cancelar </button>
                            <button type="button" class="btn btn-outline-success" onClick={handleUpdateStatus}>Actualizar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="modalDetalle" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Detalles del producto</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 text-center">
                                    {productDetail.urlimage !== null && (
                                        <div className="image mb-3">
                                            <img src={productDetail.urlimage} alt="" style={{ width: '200px', height: '200px' }} />
                                        </div>
                                    )}
                                    <h5>{productDetail.name}</h5>
                                    <div className="d-flex justify-content-evenly">
                                        <div className="text">
                                            Cantidad: {productDetail.quantity}
                                        </div>
                                        <div className="text">
                                            Precio: $ {productDetail.price}
                                        </div>
                                        <div className="text">
                                            Total: $ {productDetail.total}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="text text-justify" style={{ lineHeight: '26px' }}>
                                        {productDetail.aditional_details}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SaleViewPage;