import { Link, useNavigate } from 'react-router-dom';
import "./css/auth-boxed.css";
import { useEffect, useState } from 'react';
import { baseUrl } from "../../helpers/baseUrl";
import Cookies from 'js-cookie';
import axios from 'axios';
import { useFormik } from 'formik';
import { UserSchema } from "../../validation/UserSchema";
import Swal from 'sweetalert2';
import { Formik, Field, Form } from 'formik';
import SignUpSchema from "../../validation/SignUpSchema";

function SignupPage() {
    const navigate = useNavigate();

    const initialValues = {
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        password: ''
    }

    useEffect(() => {
        const userData = sessionStorage.getItem("userData");

        if (userData) {
            navigate("/home");
        }
    }, []);






    return (
        <div class="auth-container d-flex">
            <div class="container mx-auto align-self-center">
                <div class="row">

                    <div class="col-6 d-lg-flex d-none h-100 my-auto top-0 start-0 text-center justify-content-center flex-column">
                        <div class="auth-cover-bg-image"></div>
                        <div class="auth-overlay"></div>
                        <div class="auth-cover">
                            <div class="position-relative">
                                <img src="CYA.png" alt="auth-img" />
                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-12 d-flex flex-column align-self-center mx-auto">
                        <div class="card mt-3 mb-3">
                            <div class="card-body">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={SignUpSchema}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values) => {
                                        try {
                                            const response = await axios.post(`${baseUrl.url}auth/signup`, values, {
                                                timeout: 5000,
                                            });

                                            if (response.status === 201) {

                                                Swal.fire({
                                                    title: '¡Registro guardado!',
                                                    text: 'Tu cuenta se ha creado exitosamente, por favor, inicia sesion',
                                                    icon: 'success',
                                                    padding: '2em',
                                                    confirmButtonText: 'Aceptar',
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        navigate('/login');
                                                    }
                                                }
                                                );
                                            }

                                        } catch (error) {
                                            console.log(error);
                                            if (error.response.status === 422) {
                                                Swal.fire({
                                                    title: 'Advertencia',
                                                    text: 'No se pudo crear tu cuenta, verifique que todos los datos esten completos',
                                                    icon: 'warning',
                                                    padding: '2em',
                                                });
                                            } else {
                                                Swal.fire({
                                                    title: 'Error',
                                                    text: 'Ocurrió un error en el servidor',
                                                    icon: 'error',
                                                    padding: '2em',
                                                });
                                            }
                                        }

                                    }}>

                                    {({ handleChange, handleSubmit, errors }) => (
                                        <Form
                                            className="d-flex justify-content-center align-items-center flex-column"
                                            onSubmit={handleSubmit}
                                        >
                                            <div class="row">
                                                <div class="col-md-12 mb-3">

                                                    <h2>Crear cuenta</h2>
                                                    <p>Introduce tus datos para continuar</p>

                                                </div>
                                                <div class="col-md-12">
                                                    <div class="mb-3">
                                                        <label class="form-label">Usuario</label>
                                                        <Field
                                                            type="text"
                                                            className="form-control add-billing-address-input"
                                                            id="username"
                                                            name="username"
                                                            onChange={handleChange}
                                                        />
                                                        {errors.username ? (
                                                            <div class="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                                {errors.username}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="mb-3">
                                                        <label class="form-label">Nombre</label>
                                                        <Field
                                                            type="text"
                                                            className="form-control add-billing-address-input"
                                                            id="first_name"
                                                            name="first_name"
                                                            onChange={handleChange}
                                                        />
                                                        {errors.first_name ? (
                                                            <div class="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                                {errors.first_name}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="mb-3">
                                                        <label class="form-label">Apellido</label>
                                                        <Field
                                                            type="text"
                                                            className="form-control add-billing-address-input"
                                                            id="last_name"
                                                            name="last_name"
                                                            onChange={handleChange}
                                                        />
                                                        {errors.last_name ? (
                                                            <div class="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                                {errors.last_name}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="mb-3">
                                                        <label class="form-label">Correo</label>
                                                        <Field
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            name="email"
                                                            onChange={handleChange}
                                                        />
                                                        {errors.email ? (
                                                            <div className="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                                {errors.email}
                                                            </div>
                                                        ) : null}

                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="mb-3">
                                                        <label class="form-label">Contraseña</label>
                                                        <Field
                                                            type="password"
                                                            className="form-control"
                                                            id="password"
                                                            name="password"
                                                            onChange={handleChange}
                                                        />
                                                        {errors.password ? (
                                                            <div className="alert alert-danger alert-dismissible fade show border-0 mt-2" role="alert">
                                                                {errors.password}
                                                            </div>
                                                        ) : null}

                                                    </div>
                                                </div>


                                                <div class="col-12">
                                                    <div class="mt-2">
                                                        <button type='submit' class="btn btn-secondary w-100">CREAR CUENTA</button>
                                                    </div>
                                                </div>

                                                <div class="col-12 mt-4">
                                                    <div class="text-center">
                                                        <p class="mb-0">¿Ya tienes una cuenta?
                                                            <Link to="/login">
                                                                <a class="text-warning">
                                                                    Inicia sesion
                                                                </a>
                                                            </Link>

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}



                                </Formik>



                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default SignupPage;