import "./css/style-maintanence.css";
import Temporizador from "./Temporizador";

function Maintenance() {
    return (
        <div className="maintanence text-center layout-boxed">
            <div className="container-fluid maintanence-content">
                <div className="">
                    
                    <h1 className="error-title">En mantenimiento</h1>
                    <p className="error-text">Gracias por visitarnos</p>
                    <p className="text">Actualmente estamos trabajando en la realización de algunas mejoras para ofrecerte una mejor experiencia de usuario</p>
                    <p className="text">Visítenos de nuevo en:</p>
                    <Temporizador/> 
                    <p className="text">Atte. Equipo de desarrollo </p>
                </div>
            </div>
        </div>

    );
}

export default Maintenance; 