import Breadcrumb from '../components/Breadcrumb';
import DataTableBase from '../helpers/DataTableBase';
/* import "../assets/css/dash_1.css"; */

function AccountingPage() {
    const user = JSON.parse(sessionStorage.getItem("userData"));
    const id = user ? user.id_user : "";
    const role = user ? user.role : "";
    const columns = [
        {
            name: 'Nombre cliente',
            selector: 'nombreCliente', // Debe coincidir con el nombre de la propiedad en tus datos
            sortable: true,
        },
        {
            name: 'Cantidad de Ventas',
            selector: 'cantidadVentas',
            sortable: true,
        },
        {
            name: 'Total en Ventas',
            selector: 'totalVentas',
            sortable: true,
        },
        {
            name: '% Comision por ventas',
            selector: 'porcentajeComision',
            sortable: true,
        },
        {
            name: 'Comision correspondiente',
            selector: 'comisionCorrespondiente',
            sortable: true,
        },
    ];

    const data = [
        { nombreCliente: 'Abel Lopez', cantidadVentas: 1, totalVentas: "$ 300", porcentajeComision: "5 %", comisionCorrespondiente: 60 },
      ];

    return (
        <div className="layout-px-spacing">
            <div className="middle-content container-xxl p-0">
                <div class="page-meta">
                    <Breadcrumb options={["Inicio", "Analiticas", "Contabilidad"]} />
                </div>
                <div className="row mt-4">
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                        <div class="widget widget-card-four">
                            <div class="widget-content">
                                <div class="w-header">
                                    <div class="w-info">
                                        <h6 class="value">Ventas</h6>
                                    </div>
                                    <div class="task-action">
                                        <div class="dropdown">
                                            <a class="dropdown-toggle" href="#" role="button" id="expenses" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                            </a>

                                            <div class="dropdown-menu left" aria-labelledby="expenses" style={{ willChange: 'transform' }}>
                                                <a class="dropdown-item" href="javascript:void(0);">This Week</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Last Week</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Last Month</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="w-content">

                                    <div class="w-info">
                                        <p class="value">$ 0.00 <span>esta semana</span> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trending-up"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg></p>
                                    </div>
                                </div>
                                <div class="w-progress-stats">
                                    <div class="progress">
                                        <div class="progress-bar bg-gradient-secondary" role="progressbar" style={{ width: '57%' }} aria-valuenow="57" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div class="">
                                        <div class="w-icon">
                                            <p>57%</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                        <div class="widget widget-card-five">
                            <div class="widget-content">
                                <div class="account-box">

                                    <div class="info-box">
                                        <div class="icon">
                                            <span>
                                                <img src="https://designreset.com/cork/html/src/assets/img/money-bag.png" alt="money-bag" />
                                            </span>
                                        </div>

                                        <div class="balance-info">
                                            <h6>Total Ventas</h6>
                                            <p>$0.00</p>
                                        </div>
                                    </div>

                                    <div class="card-bottom-section">
                                        <div><span class="badge badge-light-success">+ 0% <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trending-up"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg></span></div>
                                        <a class="">Enero</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                        <div class="widget-content widget-content-table">
                                <DataTableBase columns={columns} data={data} />
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountingPage;