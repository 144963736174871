import * as Yup from 'yup';

export const productDetailSchema = Yup.object({
  quantity: Yup.number()
    .required('La cantidad es obligatoria')
    .min(1, 'La cantidad debe ser mayor que 0'),

  price: Yup.number()
    .required('El precio es obligatorio')
    .min(0.01, 'El precio debe ser mayor que 0.00'),

  aditional_details: Yup.string().test('len', 'Los detalles adicionales deben tener al menos 5 caracteres', function(value) {
    if (this.parent && this.parent.aditional_details && this.parent.aditional_details.length > 0) {
      return value.length >= 5;
    }
    return true; // Si no hay detalles adicionales, la validación pasa
  }).nullable(),
});

