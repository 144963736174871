import React, { useState, useEffect }  from "react";
import { Outlet } from "react-router-dom";
import { Link, useNavigate } from 'react-router-dom';

function UserHomePage() {
    const navigate = useNavigate();
    
    return (
        <Outlet/>
    );
}

export default UserHomePage;