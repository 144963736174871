import React, { useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const ImageSlider = ({ images }) => {
  const imageStyle = {
    width: '300px', // Definir el ancho deseado
    height: '290px', // Definir el alto deseado
    objectFit: 'contain', 
    textAlign: 'center',
  };

  return (
    <Splide
      options={{
        type: 'fade',
        heightRatio: 0.5,
        height: 'auto',
        margin: 'auto',
      }}
    >
      {images.map((image, index) => (
        <SplideSlide key={index} style={{textAlign: 'center'}} >
          <img src={image} alt={`Slide ${index + 1}`} className='img-fluid' style={imageStyle} />
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default ImageSlider;