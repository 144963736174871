import "./css/dt-global_style.css";
import "./css/invoice-list.css";
import "./css/datatables.css";
import $ from 'jquery';
import 'datatables.net';
import { useEffect, useState, useRef } from 'react';
import flatpickr from 'flatpickr';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { baseUrl } from "../../helpers/baseUrl";
import { useData } from '../../context/dataContext';
import configureAxios from "../../helpers/axiosConfig";
import DataTableBase from "../../helpers/DataTableBase";
import Breadcrumb from "../../components/Breadcrumb";

function SaleList2Page() {
    const axios = configureAxios();
    const navigate = useNavigate();
    const [sales, setSales] = useState([]);
    const { clearContext } = useData();
    const [optionSelected, setOptionSelected] = useState(1);
    const user = JSON.parse(sessionStorage.getItem("userData"));
    const id = user ? user.id_user : "";
    const columns = [
        { name: 'Venta', selector: (row) => <div>No. {row.id_order}</div>, sortable: true, },
        { name: 'Cliente', selector: (row) => <div>{row.customer_first_name} {row.customer_last_name}</div>, sortable: true, },
        { name: 'Vendedor', selector: (row) => <div>{row.seller_first_name} {row.seller_last_name}</div>, sortable: true, },
        {
            name: 'Fecha creada', selector: (row) => row.order_date, sortable: true, width: '140px', cell:
                (row) => (
                    <span className="inv-date">

                        {row.order_date}
                    </span>

                )
        },
        {
            name: 'Fecha de entrega', selector: 'fechaEntrega', sortable: true, width: '180px', cell:
                (row) => (
                    <span className="inv-date">

                        {row.delivery_date}
                    </span>
                )
        },
        {
            name: 'Estado Pedido', selector: (row) => row.status, sortable: true, width: '150px', cell:
                (row) => (
                    <span
                        className={`badge ${row.status === 'REGISTRADO'
                            ? 'badge-primary'
                            : row.status === 'EN PRODUCCION'
                                ? 'badge-info'
                                : row.status === 'FINALIZADA'
                                    ? 'badge-success'
                                    : row.status === 'CANCELADA'
                                        ? 'badge-danger'
                                        : 'badge-secondary'
                            }`}
                    >
                        {row.status}
                    </span>
                )
        },
        {
            name: 'Estado pago', selector: 'estadoPago', sortable: true, width: '150px', cell:
                (row) => (
                    <span
                        className={`badge ${row.payment_status === "PENDIENTE"
                            ? "badge-warning"
                            : row.payment_status === "PAGADO"
                                ? "badge-success"
                                : row.payment_status === "CANCELADO"
                                    ? "badge-danger"
                                    : "badge-secondary"
                            }`}
                    >
                        {row.payment_status}
                    </span>
                )
        },
        { name: 'Monto', selector: (row) => row.total, sortable: true, },
        {
            name: 'Anticipo', selector: (row) => row.has_advance, sortable: true, width: '120px', cell:
                (row) => (
                    <span
                        className={`badge ${row.has_advance ? "badge-success" : "badge-danger"
                            }`}
                    >
                        {row.has_advance ? "SI" : "NO"}
                    </span>
                )
        },
        {
            name: 'Acciones',

            selector: (row) => row.acciones,
            cell: row => (
                <>
                    <Link to={`/sales/view/${row.id_order}`}>
                        <a className="badge badge-light-primary text-start me-2 action-edit" href="javascript:void(0);">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                        </a>
                    </Link>
                    <a className="badge badge-light-danger text-start action-delete" onClick={handleDeleteModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                    </a>
                </>
            ),
            width: '150px',
        },
    ];

    const handleDeleteModal = () => {
        Swal.fire({
            title: '¿Eliminar venta?',
            text: '¿Estás seguro que deseas eliminar esta venta? ¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sí, eliminar',
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire('No se pudo eliminar', 'Esta venta no se puede eliminar', 'warning');
            }
        });
    };


    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        clearContext();
    }, []);

    const fetchData = async (status = 1) => {
        setOptionSelected(status);
        try {
            const response = await axios.get(`${baseUrl.url}sales/${id}?status=${status}`);
            if (response.status === 200) {
                setSales(response.data.data);
            }
            if (response.status === 200 & response.data.data.length === 0) {
                console.log("No se encontraron registros de ventas en el mes actual para este usuario");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    title: '¡Sesion caducada!',
                    text: 'Tu sesion ha caducado, por favor, cierra e inicia sesion de nuevo',
                    icon: 'info',
                    padding: '2em',
                    confirmButtonText: 'Aceptar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/login');
                    }
                }
                );
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Ocurrió un error en el servidor, contacta a soporte de inmediato',
                    icon: 'error',
                    padding: '2em',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Soporte',
                    cancelButtonText: 'Cancelar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.open('https://api.whatsapp.com/send?phone=50372179594', '_blank');
                    }
                });
            }
            console.error('Error al obtener los datos de las ventas: ', error);
        }
    }



    return (
        <div className="layout-px-spacing">
            <div className="middle-content container-xxl p-0">
                <div className="page-meta">
                    <Breadcrumb options={["Ventas", "Ventas", "Mis ventas"]} />
                </div>
                <div className="row" id="cancel-row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing">
                        <div className="widget-content widget-content-table br-8">
                            <div id="invoice-list_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                                <div className="inv-list-top-section">
                                    <div className="row">
                                        <div className="col-12 gap-2 d-flex justify-content-md-start justify-content-center options">
                                            <div className="dt-action-buttons align-self-center">
                                                <div className="dt-buttons">
                                                    <Link to="/sales/new">
                                                        <button className="dt-button btn btn-primary _effect--ripple waves-effect waves-light" tabIndex="0" aria-controls="invoice-list"><span>Nueva venta</span></button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="dt-action-buttons align-self-center">
                                                <div className="dt-buttons">
                                                    <button onClick={() => fetchData(1)} className={`btn ${optionSelected === 1 ? 'btn-primary' : 'btn-outline-primary'} _effect--ripple waves-effect waves-light`} tabIndex="0" aria-controls="invoice-list"><span>Registradas</span></button>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="dt-buttons">
                                                    <button onClick={() => fetchData(2)} className={`btn ${optionSelected === 2 ? 'btn-secondary' : 'btn-outline-secondary'} _effect--ripple waves-effect waves-light ${optionSelected === 2 ? 'active' : ''}`} tabIndex="0" aria-controls="invoice-list"><span>En produccion</span></button>
                                                </div>
                                            </div>
                                            <div className="dt-action-buttons align-self-center">
                                                <div className="dt-buttons">
                                                    <button onClick={() => fetchData(3)} className={`btn ${optionSelected === 3 ? 'btn-success' : 'btn-outline-success'} _effect--ripple waves-effect waves-light ${optionSelected === 3 ? 'active' : ''}`} tabIndex="0" aria-controls="invoice-list"><span>Finalizadas</span></button>
                                                </div>
                                            </div>
                                            <div className="dt-action-buttons align-self-center">
                                                <div className="dt-buttons">
                                                    <button onClick={() => fetchData(4)} className={`btn ${optionSelected === 4 ? 'btn-danger' : 'btn-outline-danger'} _effect--ripple waves-effect waves-light ${optionSelected === 4 ? 'active' : ''}`}><span>Canceladas</span></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-sm-12 col-md-12 d-flex justify-content-md-start justify-content-center">
                                                {optionSelected === 1 && (
                                                    <p>Mostrando mis ventas registradas</p>
                                                )}
                                                {optionSelected === 2 && (
                                                    <p>Mostrando mis ventas en producción</p>
                                                )}
                                                {optionSelected === 3 && (
                                                    <p>Mostrando mis ventas finalizadas</p>
                                                )}
                                                {optionSelected === 4 && (
                                                    <p>Mostrando mis ventas canceladas</p>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <DataTableBase columns={columns} data={sales} />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default SaleList2Page; 