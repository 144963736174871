import React, { useEffect, useRef, useState } from 'react';
import flatpickr from 'flatpickr';
import "../css/invoice-preview.css";
import { baseUrl } from "../../../helpers/baseUrl";
import "../css/flatpickr.css";
import "../css/custom-flatpickr.css";
import { useData } from '../../../context/dataContext';
import configureAxios from "../../../helpers/axiosConfig";



function SaleFormPage04() {
    const axios = configureAxios();
    const flatpickrRef = useRef(null);
    const user = JSON.parse(sessionStorage.getItem("userData"));
    const id_user = user.id_user; 
    const [hasAdvance, setHasAdvance] = useState(false);
    const currentDate = new Date().toISOString().split('T')[0];
    const { advancesData, setAdvancesData } = useData();
    const [payments, setPayments] = useState([]);

    useEffect(() => {
        if (hasAdvance) {
            flatpickr(flatpickrRef.current, {
                noCalendar: false,
                enableTime: false,
                dateFormat: 'Y-m-d',
            }, []);
        }
    }, [hasAdvance]);

    useEffect(() => {
        getPaymentsMethods();
    }, []);

    const getPaymentsMethods = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}payments`);

            setPayments(response.data.data);

        } catch (error) {
            console.error('Error al obtener los datos de las ventas: ', error);
        }
    }

    const handleRadioChange = (event) => {
        if (event.target.value === 'option1') {
            setHasAdvance(true);
        } else {
            setHasAdvance(false);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setAdvancesData({
            ...advancesData,
            [name]: value,
        });
    };

    const handleDateChange = () => {
        const newDate = flatpickrRef.current.value;
        setAdvancesData((prevAdvancesData) => ({
            ...prevAdvancesData,
            date_received: newDate, 
            created_by: id_user
        }));
    };


    return (
        <div className="invoice-address-client-fields">
            <h6>Detalles del anticipo
            </h6>
            <p>Si el cliente dejo anticipo, debe completar TODA la informacion, de lo contrario, este no se registrara</p>
            <div className="form-check">
                <input className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="option1"
                    checked={hasAdvance}
                    onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    El cliente dejo anticipo
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="option2"
                    checked={!hasAdvance}
                    onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                    El cliente no dejo anticipo
                </label>
            </div>

            {hasAdvance && (
                <>
                    <div className="form-group row my-2">
                        <label htmlFor="client-name" className="col-sm-3 col-form-label col-form-label-sm">Fecha recibido</label>
                        <div className="col-sm-9">
                            <input type="date" ref={flatpickrRef} onInput={handleDateChange} className="form-control form-control-sm" id="client-email" />
                        </div>
                    </div>
                    <div className="form-group row my-2">
                        <label htmlFor="client-name" className="col-sm-3 col-form-label col-form-label-sm">Cantidad</label>
                        <div className="col-sm-9">
                            <input type="number" list="customerOptions" className="form-control form-control-sm" name='quantity' value={advancesData.quantity} onChange={handleInputChange} required />
                        </div>
                    </div>
                    <div className="form-group row my-2">
                        <label htmlFor="client-name" className="col-sm-3 col-form-label col-form-label-sm" >Recibido por</label>
                        <div className="col-sm-9">
                            <input type="text" list="customerOptions" className="form-control form-control-sm" name='received_by' value={advancesData.received_by} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="form-group row my-2">
                        <label htmlFor="client-name" className="col-sm-3 col-form-label col-form-label-sm">Metodo de pago</label>
                        <div className="col-sm-9">
                            <select className="form-control form-control-sm" name='id_payment_method' value={advancesData.id_payment_method} onChange={handleInputChange}>
                                {payments.map((payment) => (
                                    <option key={payment.id_payment} value={payment.id_payment}>
                                        {payment.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row my-2">
                        <label htmlFor="client-name" className="col-sm-3 col-form-label col-form-label-sm">Detalles</label>
                        <div className="col-sm-9">
                            <textarea className='form-control form-control-sm' rows="2" name='details' value={advancesData.details} onChange={handleInputChange}></textarea>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default SaleFormPage04;